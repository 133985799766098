import React, { forwardRef, useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import { symbol } from 'd3'

export const PieChart = forwardRef(({ data, style }, ref) => {
  const charRef = useRef(null)
  useEffect(() => {
    console.log(data)
    const myChart = echarts.init(charRef.current)
    let option = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        orient: 'vertical',
        right: 10,
        top: 20,
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 40,
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: data
        }
      ]
    }

    myChart.setOption(option)
  }, [data])
  return <div id='chart' ref={charRef} style={style}></div>
})
