import React, { useRef, useImperativeHandle, forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { CommonFormEdit, CommonRule } from 'components/LoongInCommon'
import { createDataDictionaryDetail, updateDataDictionaryDetail } from 'common/endpoints'
import { getClientHeight } from 'slices/appSlice'
import { t } from 'i18next'
import { createParameterItems, updateParameterItems } from 'common/apis/ezreport'

export const FormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  //校验规则
  const rules = {
    code: [CommonRule.Require],
    displayText: [CommonRule.Require],
    order: [CommonRule.Require],
  }
  //初始化数据
  const initData = {
    catalogName: '',
    key: '',
    name: '',
    inputType: '',
    showDescript: false,
    description: '',
  }
  //表单项
  const formItem = [
    {
      type: 'text',
      dataType: 'text',
      label: t('dataDictionary.typeDisplayText'),
      fieldName: 'catalogName',
      visible: !formData || !!formData.catalogName,
      disabled: true,
    },
    {
      type: 'text',
      dataType: 'text',
      label: '编码',
      fieldName: 'key',
      maxLength: 256,
      disabled: formData && formData.id,
    },
    {
      type: 'text',
      dataType: 'text',
      label: '名称',
      fieldName: 'name',
      maxLength: 256,
    },
    {
      type: 'dropdown',
      dataType: 'text',
      label: '输入方式',
      fieldName: 'inputType',
      options: [
        { label: '单选', value: 'radio' },
        { label: '多选', value: 'check' },
        { label: '文本输入', value: 'input' },
        { label: '地理位置', value: 'location' },
      ],
    },
    {
      type: 'text',
      dataType: 'number',
      label: '失效倍数',
      fieldName: 'failureMultiple',
    },
    {
      type: 'text',
      dataType: 'number',
      label: '设定权重',
      fieldName: 'weightBase',
    },
    {
      type: 'checkbox',
      dataType: 'bool',
      label: '是否需要输入备注',
      fieldName: 'showDescript',
      maxLength: 512,
    },
    {
      type: 'text',
      dataType: 'text',
      label: '描述',
      fieldName: 'description',
      maxLength: 512,
    },
  ]
  //获取更新的输入参数
  const getUpdateInput = (inputdata) => {
    return {
      name: inputdata.name,
      description: inputdata.description,
      key: inputdata.key,
      failureMultiple: inputdata.failureMultiple,
      weights: {
        base: inputdata.weightBase,
      },
      riskLevel: inputdata.riskLevel,
      showDescript: inputdata.showDescript,
      inputType: inputdata.inputType,
      projectType: 'wfm',
    }
  }
  //获取添加的输入参数
  const getCreateInput = (inputdata) => {
    return {
      catalogId: inputdata.catalogId,
      name: inputdata.name,
      description: inputdata.description,
      failureMultiple: inputdata.failureMultiple,
      weights: {
        base: inputdata.weightBase,
      },
      riskLevel: inputdata.riskLevel,
      key: inputdata.key,
      showDescript: inputdata.showDescript,
      inputType: inputdata.inputType,
      projectType: 'wfm',
    }
  }

  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title={t('dataDictionary.formTitle')}
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createParameterItems}
      updateData={updateParameterItems}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={{
        ...formData,
        weightBase: formData?.weights?.base,
      }}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    />
  )
})
