//菜单
export const admin =
  //管理
  [
    {
      title: "m_overview", //总览
      path: "/admin",
      icon: "abb/home",
    },
    {
      title: "m_admin_sync", //'账户管理2',
      path: "/admin/sync",
      icon: "abb/synchronize",
      permission: "AbpIdentity.Setting",
    },
    {
      title: "m_admin_users", //'账户管理2',
      path: "/admin/users",
      icon: "abb/user",
      permission: "AbpIdentity.Users",
    },
    {
      title: "m_admin_role", //'角色管理',
      path: "/admin/role",
      icon: "abb/user-in-circle",
      permission: "AbpIdentity.Roles",
    },
    {
      title: "m_admin_Organization", //'组织管理',
      path: "/admin/Organization",
      icon: "abb/group",
      permission: "AbpIdentity.OrganizationUnitManagement",
    },
    // {
    //   title: "m_admin_Tenant", //'租户管理',
    //   path: "/admin/Tenant",
    //   icon: "abb/share",
    //   permission:"AbpTenantManagement.Tenants",
    // },
    {
      title: "m_admin_settings", //'配置',
      path: "/admin/settings",
      icon: "abb/advanced-settings",
      permission: "AbpIdentity.Setting",
    },
    {
      title: "m_admin_dataDictionary", //'字典',
      path: "/admin/dataDictionary",
      icon: "abb/tiles",
      permission: "AbpIdentity.DataDictionaryManagement",
    },
    {
      title: "m_admin_auditLogs", //'审计日志',
      path: "/admin/auditLogs",
      icon: "abb/calendar",
      permission: "AbpIdentity.AuditLog",
    },
    {
      title: "系统权重字典管理", //'账户管理2',
      path: "/admin/importance-weight",
      icon: "abb/numericals",
      permission: "EzReport.TestItem",
    },

    // {
    //   title: "m_admin_resource", //'文件管理',
    //   path: "/admin/resource",
    //   icon: "abb/folder",
    // },
    // {
    //   title: "m_admin_logs", //'日志',
    //   path: "/admin/logs",
    //   icon: "abb/envelope",
    // },
  ]
export const maindata =
  //管理
  [
    {
      title: "m_overview", //总览
      path: "/maindata",
      icon: "abb/home",
    },
    {
      title: "m_maindata_factories", //'账户管理2',
      path: "/maindata/factories",
      icon: "abb/factory",
      permission: "MainData.Factory",
    },
    {
      title: "m_maindata_customers", //'账户管理2',
      path: "/maindata/customers",
      icon: "abb/globe",
      permission: "MainData.Customer",
    },
    {
      title: "m_maindata_distributors", //'经销商',
      path: "/maindata/distributors",
      icon: "abb/boolean",
      permission: "MainData.Distributor",
    },
    {
      title: "m_maindata_clients", //'账户管理2',
      path: "/maindata/clients",
      icon: "abb/transformer",
      permission: "MainData.Station",
    },
    {
      title: "m_maindata_projects", //'账户管理2',
      path: "/maindata/projects",
      icon: "abb/briefcase",
      permission: "MainData.Project",
    },
    {
      title: "m_maindata_deviceTypes", //'账户管理2',
      path: "/maindata/deviceTypes",
      icon: "abb/devices",
      permission: "MainData.DeviceType",
    },
    {
      title: "m_maindata_materialCatalogs", //'账户管理2',
      path: "/maindata/materialCatalogs",
      icon: "abb/thumbnail-view",
      permission: "MainData.MaterialCatalog",
    },
    {
      title: "m_maindata_materials", //'账户管理2',
      path: "/maindata/materials",
      icon: "abb/hardware-devices",
      permission: "MainData.Material",
    },
    {
      title: "m_maindata_materialBom", //'账户管理2',
      path: "/maindata/MaterialBom",
      icon: "abb/hardware-devices",
      permission: "MainData.OrderBom",
    },
    {
      title: "m_maindata_instruments", //'账户管理2',
      path: "/maindata/instruments",
      icon: "abb/test",
      permission: "MainData.Instrument",
    },
    {
      title: '文档管理', //'账户管理2',
      path: '/maindata/documents',
      icon: 'abb/folder',
      //permission: 'Wechat.TestItem',
    },
    {
      title: "m_maindata_applications", //'账户管理2',
      path: "/maindata/applications",
      icon: "abb/software-program",
      permission: "MainData.Application",
    },
    {
      title: "m_maindata_applicationgroups", //'账户管理2',
      path: "/maindata/applicationgroups",
      icon: "abb/software-program-and-modules",
      permission: "MainData.ApplicationGroup",
    },
    {
      title: "m_maindata_banners", //'账户管理2',
      path: "/maindata/banners",
      icon: "abb/image",
      permission: "MainData.Banner",
    },
    {
      title: "m_maindata_rights", //'账户管理2',
      path: "/maindata/rights",
      icon: "abb/image",
      permission: "MainData.Rights",
    },
    {
      title: 'm_maindata_questionnaires', //'问卷调查',
      path: '/maindata/questionnaires',
      icon: 'abb/help-circle-2',
      permission: 'MainData.QuQuestionnaire',
    },
    {
      title: 'm_maindata_saprfc', //'sap rfc 测试',
      path: '/maindata/saprfctest',
      icon: 'abb/help-circle-2',
      //permission: 'MainData.Saprfc',
    },
  ]
export const report =
  //报表
  [
    {
      title: "m_overview", //总览
      path: "/report",
      icon: "abb/home",
    },
    {
      title: "m_report_smslogs", //'短信日志',
      path: "/report/smslogs",
      icon: "abb/envelope",
    },
  ]

export const remoteSupport =
  //报表
  [
    {
      title: "m_overview", //总览
      path: "/remoteSupport",
      icon: "abb/home",
    },
    {
      title: "专家管理", //总览
      path: "/remoteSupport/experts",
      icon: "abb/user",
    },
  ]

export const wfreport =
  //数字维保
  [
    {
      title: "m_overview", //总览
      path: "/wfreport",
      icon: "abb/home",
    },
    {
      title: "m_maindata_projects", //'账户管理2',
      path: "/wfreport/projects",
      icon: "abb/briefcase",
      permission: "MainData.Project",
    },
    {
      title: "m_ezreport_reports", //'账户管理2',
      path: "/wfreport/reports",
      icon: "abb/reports",
      permission: "EzReport.Report",
    },
    {
      title: "m_ezreport_checkItems", //'账户管理2',
      path: "/wfreport/checkItems",
      icon: "abb/check-mark-circle-1",
      permission: "EzReport.CheckItem",
    },
    {
      title: "m_ezreport_testItems", //'账户管理2',
      path: "/wfreport/testItems",
      icon: "abb/test",
      permission: "EzReport.TestItem",
    },
    {
      title: "站点参数管理", //'账户管理2',
      path: "/wfreport/stationParameters",
      icon: "abb/transformer",
      permission: "EzReport.TestItem",
    },
    // {
    //   title: '系统权重字典管理', //'账户管理2',
    //   path: '/wfreport/importance-weight',
    //   icon: 'abb/numericals',
    //   permission: 'EzReport.TestItem',
    // },
    {
      title: "m_ezreport_testModals", //'账户管理2',
      path: "/wfreport/testModals",
      icon: "abb/template",
      permission: "EzReport.TestModal",
    },
    {
      title: "m_ezreport_deviceTypes", //'设备类型管理',
      path: "/wfreport/deviceTypes",
      icon: "abb/devices",
      permission: "EzReport.MaintenanceCatalog.Bind",
    },
  ]

export const ezreport =
  //数字维保
  [
    {
      title: "m_overview", //总览
      path: "/ezreport",
      icon: "abb/home",
    },
    {
      title: "m_maindata_projects", //'账户管理2',
      path: "/ezreport/projects",
      icon: "abb/briefcase",
      permission: "MainData.Project",
    },
    {
      title: "m_ezreport_reports", //'账户管理2',
      path: "/ezreport/reports",
      icon: "abb/reports",
      permission: "EzReport.Report",
    },
    {
      title: "m_ezreport_checkItems", //'账户管理2',
      path: "/ezreport/checkItems",
      icon: "abb/check-mark-circle-1",
      permission: "EzReport.CheckItem",
    },
    {
      title: "m_ezreport_testItems", //'账户管理2',
      path: "/ezreport/testItems",
      icon: "abb/test",
      permission: "EzReport.TestItem",
    },
    {
      title: "m_ezreport_testModals", //'账户管理2',
      path: "/ezreport/testModals",
      icon: "abb/template",
      permission: "EzReport.TestModal",
    },
    {
      title: "m_ezreport_deviceTypes", //'设备类型管理',
      path: "/ezreport/deviceTypes",
      icon: "abb/devices",
      permission: "EzReport.MaintenanceCatalog.Bind",
    },
  ]

export const inspection =
  //数字维保
  [
    {
      title: "m_overview", //总览
      path: "/inspection",
      icon: "abb/home",
    },
    {
      title: "m_maindata_projects", //'账户管理2',
      path: "/inspection/projects",
      icon: "abb/briefcase",
      permission: "MainData.Project",
    },
    {
      title: "m_inspection_reports", //'账户管理2',
      path: "/inspection/reports",
      icon: "abb/reports",
      permission: "EzReport.Report",
    },
    {
      title: "m_ezreport_checkItems", //'账户管理2',
      path: "/inspection/checkItems",
      icon: "abb/check-mark-circle-1",
      permission: "EzReport.CheckItem",
    },
    {
      title: "m_ezreport_testItems", //'账户管理2',
      path: "/inspection/testItems",
      icon: "abb/test",
      permission: "EzReport.TestItem",
    },
    {
      title: "m_ezreport_deviceTypes", //'设备类型管理',
      path: "/inspection/deviceTypes",
      icon: "abb/devices",
      permission: "EzReport.MaintenanceCatalog.Bind",
    },
  ]

export const evaluation =
  //数字维保
  [
    {
      title: "m_overview", //总览
      path: "/evaluation",
      icon: "abb/home",
    },
    {
      title: "m_maindata_projects", //'账户管理2',
      path: "/evaluation/projects",
      icon: "abb/briefcase",
      permission: "MainData.Project",
    },
    {
      title: "评估报告", //'账户管理2',
      path: "/evaluation/reports",
      icon: "abb/reports",
      permission: "EzReport.Report",
    },
    {
      title: "m_ezreport_checkItems", //'账户管理2',
      path: "/evaluation/checkItems",
      icon: "abb/check-mark-circle-1",
      permission: "EzReport.CheckItem",
    },
    {
      title: "站点参数管理", //'账户管理2',
      path: "/evaluation/stationParameters",
      icon: "abb/transformer",
      permission: "EzReport.TestItem",
    },
    {
      title: "公式维护", //'账户管理2',
      path: "/evaluation/formulas",
      icon: "abb/sld-1",
      permission: "EzReport.TestItem",
    },
    {
      title: "m_ezreport_deviceTypes", //'设备类型管理',
      path: "/evaluation/deviceTypes",
      icon: "abb/devices",
      permission: "EzReport.MaintenanceCatalog.Bind",
    },
  ]

export const wechat = [
  {
    title: "m_overview", //总览
    path: "/evaluation",
    icon: "abb/home",
  },
  {
    title: "微信菜单管理", //'账户管理2',
    path: "/wechat/menus",
    icon: "abb/menu",
    permission: "Wechat.Menu",
  },
  {
    title: "微信标签管理", //'账户管理2',
    path: "/wechat/tags",
    icon: "abb/label",
    permission: "Wechat.Tag",
  },
  {
    title: "微信用户管理", //'账户管理2',
    path: "/wechat/users",
    icon: "abb/user",
    permission: "Wechat.User",
  },
  {
    title: "微信关键字管理", //'账户管理2',
    path: "/wechat/keywords",
    icon: "abb/text",
    permission: "Wechat.Keyword",
  },
  {
    title: "微信素材管理", //'账户管理2',
    path: "/wechat/materials",
    icon: "abb/plugin",
    permission: "Wechat.Material",
  },
  {
    title: "微信文档管理", //'账户管理2',
    path: "/wechat/documents",
    icon: "abb/folder",
    permission: "Wechat.Document",
  },
]
