import React, { useEffect, useState, useRef } from 'react'
import NavDropdown from './NavDropdown'
import { useDispatch, useSelector } from "react-redux";
import { Icon, Link, LoadingIndicator, WithTooltip, Tooltip, Spinner, Button } from '@abb/abb-common-ux-react'
import { getEnv } from 'slices/envSlice';
 
import { getEventDetailsById } from 'common/endpoints'
import axios from 'axios'
import { NotificationType, showNotification } from 'components/Notification/notificationSlice';
import moment from 'moment'
import { useMountedState } from 'common/useMountedState'
import { ResponseResult } from 'common/request';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from 'hooks/useCurrentUser';
import useSignalR from 'hooks/useSignalR';

export default function NotificationList() {
  const currentUser = useCurrentUser()

  const isMounted = useMountedState()
  const history = useHistory();
  const user = currentUser.getUser()

  const oauth = useSelector(state => state.oauth);
  const token = user?.token;
  const env = useSelector(getEnv)
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
 
  const [notifications, setNotifications] = useState([])
  const [historyCount, setHistoryCount] = useState(0)
  const [catchError, setCatchError] = useState(false)
  const [now, setNow] = useState(moment())
  const interval = useRef(null)
  const hubConnection = useSignalR(`${env.apiBase}api/SignalR/Notification`,token);
  hubConnection && hubConnection.on('ReceiveMessage', (data) => {
    console.log(`ReceiveMessage: ${data}`);
    // omit logic
  });
  const pageIndex = useRef(-1)
  const maxPageIndex = useRef(0)
  const pageSize = 10
  const source = useRef(null);
  const { t } = useTranslation()

  useEffect(() => {
    interval.current = setInterval(() => {
      setNow(moment())
    }, 10 * 1000)
    return () => {
      if (interval.current) {
        clearInterval(interval.current)
      }
    }
  }, [])
  useEffect(() => {
    interval.current = setInterval(() => {
      setNow(moment())
    }, 10 * 1000)
    return () => {
      if (interval.current) {
        clearInterval(interval.current)
      }
    }
  }, [])

  const onClickNotification = (e) => {
    console.log(e)
    
  }

  const count = catchError ? 0 : notifications.filter(n => n.isNew).length + historyCount;
  return (
    <>
      <NavDropdown navButton={
        <div className="notification-button">
          <Icon name="abb/alarm-bell" />
          {(!catchError && count > 0) &&
            <label className="notification-count-label">{count > 99 ? `99+` : count}</label>}
        </div>
      } disabled={!initialized} >
        {catchError &&
          <div>
            <p className="text-center"><Button text={t('refresh')} type="discreet-black" sizeClass="small"  /></p>
          </div>}
        {!catchError &&

          <div style={{ fontSize: 14 }}>
            {isLoading && pageIndex.current === 0 && count === 0 &&
              <div className="nav-dropdown-body" style={{ minHeight: 240 }}>
                <div className="central-position">
                  <LoadingIndicator type="radial" sizeClass="small" color="blue" />
                </div>
              </div>}
            {!isLoading && pageIndex.current === 0 && count === 0 &&
              <div className="nav-dropdown-body" style={{ minHeight: 240 }}>
                <div className="text-gray central-position"><small>{t('noNewMessage')}</small></div>
              </div>}
            {pageIndex.current >= 0 && count > 0 &&
              <>
                <div className="nav-dropdown-body no-padding" style={{ minHeight: 240, overflow: 'auto' }}>
                  <ul className="notification-list">
                    {notifications.map(n => {
                      const eventTime = moment(n.CreationTime)
                      const min = now.diff(eventTime, 'minutes')
                      const hr = now.diff(moment(n.CreationTime), 'hours')
                      let dateDesc = eventTime.format('MM-DD HH:mm')
                      if (min < 1) {
                        dateDesc = t('justNow') //刚刚
                      } else if (min < 60) {
                        dateDesc = `${min+t('minuteBefore')}` //分钟前
                      } else if (hr < 2) {
                        dateDesc = `${hr+t('hourBefore')}` //小时前
                      }
                      return (
                        <li key={n.Id}>
                          <div className="notification-item" onClick={() => onClickNotification(n)}>
                            <div className="notification-header">
                              <div className="notification-title">{n.Subject}</div>
                              <div className="notification-date">
                                <WithTooltip>
                                  <span>{dateDesc}</span>
                                  <Tooltip>{eventTime.format('YYYY-MM-DD HH:mm')}</Tooltip>
                                </WithTooltip>
                              </div>
                            </div>
                            <div className="notification-content">{n.Content}</div>
                          </div>
                        </li>
                      )
                    })}

                    {isLoading && <li><div className="btn-more disabled"><Spinner /></div></li>}
                    {pageIndex.current < maxPageIndex.current && !isLoading &&
                      <li onClick={e => {  e.stopPropagation() }}>
                    <div className="btn-more">{t('more')}</div></li>}
                  </ul>
                </div>
                <div className="nav-dropdown-footer no-padding">
                  <div className='text-right'>
                    <Link underlined={false} discreet>{t('allHasRead')}</Link></div>
                </div>
              </>}
          </div>}
      </NavDropdown>
      
    </>
  )
}
