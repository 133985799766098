import React, { useEffect, useState } from 'react'
import { getEnv } from 'slices/envSlice'
import { updateAccessToken } from 'slices/oauthSlice'
import { useLocation, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import decode from 'jwt-decode'
import { UserManager } from 'oidc-client'
import axios from 'axios'
import { useAzureAdB2CConfig } from 'hooks/useAzureAdB2CConfig'
import { useCurrentUser } from 'hooks/useCurrentUser'
import { fetchGetDataDictionaryAsync } from 'slices/dataDictionarySlice'
import { fetchGetUsersAsync } from 'slices/userSlice'
import { fetchAbpApplicationConfigurationAsync } from 'slices/abpApplicationConfigurationSlice'

const KEY_ID_TOKEN = 'identityserver4.id_token'
const KEY_ACCESS_TOKEN = 'identityserver4.access_token'

const checkAuth = (config) => {
  const idToken = localStorage.getItem(KEY_ID_TOKEN)
  const accessToken = localStorage.getItem(KEY_ACCESS_TOKEN)
  if (!idToken || !accessToken) {
    return false
  }
  try {
    const { exp, iss } = decode(accessToken)
    if (exp < new Date().getTime() / 1000) {
      return false
    } else if (iss !== config.authority) {
      return false
    } else {
      return true
    }
  } catch (e) {
    return false
  }
}

export default function AuthIdentityServer4({ children }) {
  const env = useSelector(getEnv)

  return env.authType === 'IdentityServer' ? <AuthByIdentityServer4>{children}</AuthByIdentityServer4> : children
}

function AuthByIdentityServer4({ children }) {
  const env = useSelector(getEnv)
  const [initialized, setInitialized] = useState(false)
  const currentUser = useCurrentUser()

  const config = useAzureAdB2CConfig()
  const oauth = useSelector((state) => state.oauth)
  const dispatch = useDispatch()
  const location = useLocation()
  const user = currentUser.getUser()
  const authStatus = checkAuth(config)

  useEffect(() => {
    if (user) {
      debugger
      if (!initialized && user.result.succeeded) {
        dispatch(fetchGetDataDictionaryAsync())
        dispatch(fetchGetUsersAsync())
        dispatch(fetchAbpApplicationConfigurationAsync())
        setInitialized(true)
      }
    }
  }, [user, initialized])

  if (location.pathname === config.signinCallback) {
    return <OAuthCallback />
  } else if (authStatus) {
    if (!oauth.initialized) {
      const idToken = localStorage.getItem(KEY_ID_TOKEN)
      const accessToken = localStorage.getItem(KEY_ACCESS_TOKEN)
      dispatch(updateAccessToken({ idToken, accessToken }))
    }
  } else {
    // console.log(window.location, location, "AuthByIdentityServer4: signinRedirect")
    localStorage.removeItem(KEY_ID_TOKEN)
    localStorage.removeItem(KEY_ACCESS_TOKEN)
    localStorage.removeItem('identityserver4.refresh_token')
    localStorage.setItem('identityserver4.callback_redirect', `${window.location.pathname}${window.location.search}`)
    new UserManager({
      authority: config.authority,
      client_id: config.clientId,
      redirect_uri: `${window.location.origin}${config.signinCallback}`,
      response_type: 'id_token token',
      scope: config.scope,
      post_logout_redirect_uri: `${window.location.origin}/`,
    }).signinRedirect()
    return null
  }

  return children
}

const OAuthCallback = () => {
  const location = useLocation()
  const currentUser = useCurrentUser()
  const urlHash = location.hash.split('&')
  const idToken = urlHash[0].split('=')[1]
  const accessToken = urlHash[1].split('=')[1]
  // console.log(window.location, location, idToken, decode(idToken), accessToken, decode(accessToken))

  if (idToken && accessToken) {
    // console.log("AuthByIdentityServer4 -> OAuthCallback: OAuthCallback")
    localStorage.setItem(KEY_ID_TOKEN, idToken)
    localStorage.setItem(KEY_ACCESS_TOKEN, accessToken)
    currentUser.setUser({
      token: accessToken,
      result: { succeeded: true },
    })
  }
  const callbackRedirect = localStorage.getItem('identityserver4.callback_redirect')
  window.location.assign(callbackRedirect ? callbackRedirect : '/')
  return <Redirect to={callbackRedirect ? callbackRedirect : '/'} />
}
