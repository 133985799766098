import { useState, useEffect } from 'react'
import * as signalR from '@microsoft/signalr'

export default function useSignalR(hubUrl,token) {
  const [hubConnection, setHubConnection] = useState(null)

  useEffect(() => {
    const newConnection = new signalR.HubConnectionBuilder().withUrl(hubUrl,{ accessTokenFactory: () => token }).withAutomaticReconnect().build()
    setHubConnection(newConnection)
  },[])

  useEffect(() => {
    if (hubConnection) {
      hubConnection
        .start()
        .then(() => {
          console.log('SignalR Connected!')
        })
        .catch((err) => {
          console.log('SignalR Connection Error: ', err)
        })
    }

    return () => {
      if (hubConnection) {
        hubConnection
          .stop()
          .then(() => {
            console.log('SignalR Disconnected!')
          })
          .catch((err) => {
            console.log('SignalR Disconnection Error: ', err)
          })
      }
    }
  }, [hubConnection])

  return hubConnection
}
