import React, { useState } from "react"
import { exportOrderBom, getOrderBom } from "common/apis/maindata"
import { useDispatch, useSelector } from "react-redux"
import { CommonIndex } from "components/LoongInCommon"
import { t } from "i18next"
import {
  NotificationType,
  showNotification,
} from "components/Notification/notificationSlice"
import { Button } from "@abb/abb-common-ux-react"
import { getEnv } from "slices/envSlice"
export default () => {
  const env = useSelector(getEnv)
  const dispatch = useDispatch()
  let columns = [
    {
      title: "materialBom.purchaseCode",
      key: "mat_BOL",
    },
    {
      title: "materialBom.materialCode",
      key: "idnrk",
    },
    {
      title: "materialBom.desc",
      key: "maktx",
    },
    {
      title: "materialBom.supplier",
      key: "namE1",
    },
    {
      title: "materialBom.unitPrice",
      key: "verpr",
    },
    {
      title: "materialBom.standardPrice",
      key: "stprs",
    },
    {
      title: "materialBom.Price",
      key: "amount",
    },
    {
      title: "materialBom.requireQty",
      key: "t_QTY",
    },
    {
      title: "materialBom.supplierCode",
      key: "lifnr",
    },
    {
      title: "materialBom.leadTime",
      key: "aplfz",
    },
    {
      title: "materialBom.remark",
      key: "mess",
    },
  ]
  const searchItem = [
    {
      type: "text",
      dataType: "text",
      label: t("t_keyword"),
      fieldName: "filter",
      showClearIcon: true,
      placeholder: t("materialBom.writekeyword"),
    },
    {
      type: "text",
      dataType: "text",
      label: (
        <div>
          <span style={{ color: "red" }}>* </span>
          {t("materialBom.project")}
        </div>
      ),
      fieldName: "so",
      showClearIcon: true,
      placeholder: t("materialBom.writeproject"),
    },
    {
      type: "text",
      dataType: "text",
      label: t("materialBom.ci"),
      fieldName: "ci",
      showClearIcon: true,
      placeholder: t("materialBom.writeci"),
    },
    {
      type: "text",
      dataType: "text",
      label: t("materialBom.ti"),
      fieldName: "ti",
      showClearIcon: true,
      placeholder: t("materialBom.writeti"),
    },
  ]
  const [searchData, setSearchData] = useState({})
  const onSearchDataChange = (newData, oldData) => {
    setSearchData(newData)
  }
  const onInterceptQuery = (query) => {
    // 拦截查询校验
    if (!searchData?.so) {
      dispatch(showNotification(NotificationType.Info, "项目号需要必填"))
      return
    }
    if (!searchData?.ci && !searchData?.ti) {
      dispatch(
        showNotification(NotificationType.Info, "CI号或TI号需要必填一个")
      )
      return
    }
    query()
  }
  const getGetListInput = (params) => {
    return {
      ...params,
      so: params?.so ? params?.so : "",
      ci: params?.ci ? params?.ci : "",
      ti: params?.ti ? params?.ti : "",
    }
  }
  const onExport = () => {
    // 拦截查询校验
    if (!searchData?.so) {
      dispatch(showNotification(NotificationType.Info, "项目号需要必填"))
      return
    }
    if (!searchData?.ci && !searchData?.ti) {
      dispatch(
        showNotification(NotificationType.Info, "CI号或TI号需要必填一个")
      )
      return
    }
    const params = { ...searchData }
    window.location.href = `${
      env.apiBase + (env.apiBase.endsWith("/") ? "" : "/")
    }${exportOrderBom(params)}`
  }
  const toolChildren = (
    <>
      <Button
        sizeClass="medium"
        icon="abb/export"
        text="导出"
        onClick={onExport}
      ></Button>
    </>
  )
  return (
    <>
      <CommonIndex
        columns={columns}
        searchItem={searchItem}
        searchData={searchData}
        toolChildren={toolChildren}
        onSearchDataChange={onSearchDataChange}
        getList={getOrderBom}
        enableSorting={true}
        enableQueryOnSearchDataChange={false}
        interceptQuery={onInterceptQuery}
        refreshWhenOpen={false}
        getGetListInput={getGetListInput}
      />
    </>
  )
}
