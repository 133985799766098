import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react'
import { Checkbox, Dialog, LoadingIndicator } from '@abb/abb-common-ux-react'
import { getReportDetail, getTotalReportDetail } from 'common/apis/totalreports'
import '../../../../styles/totalReport.scss'

import axios from 'axios'
import moment from 'moment'
import { Image } from 'components/Image'
import envs from './enviroment.json'
import { useEffect } from 'react'
import * as echarts from 'echarts'
import { groupBy, reduce, sumBy, uniq, uniqBy } from 'lodash'
import { tr } from 'date-fns/locale'
import { nanoid } from 'nanoid'
import { ScatterChart } from './ScatterChart'
import { PieChart } from './PieChart'

export const TotalReport = forwardRef(({ report, readonly = false }, ref) => {
  const [detail, setDetail] = useState(null)
  const [summaries, setSummaries] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const formatter=new Intl.NumberFormat("en-US", {style: "percent",maximumFractionDigits: 1})
  const totalStatus = [
    { key: -2, title: '已退回' },
    { key: -1, title: '已驳回' },
    { key: 0, title: '待编辑' },
    { key: 1, title: '待审核' },
    { key: 2, title: '待生成' },
    { key: 3, title: '生成中' },
    { key: 4, title: '已生成' },
    { key: 5, title: '等待生成' },
  ]
  const totalStatusText = [
    { key: -2, title: '退回意见' },
    { key: -1, title: '驳回意见' },
  ]
  let index = 0
  
  const fetchPreviewInfo = (reportId) => {
    setIsLoading(true)
    axios.get(getReportDetail(), { params: { id: reportId } }).then((response) => {
      const data = response.data
      setDetail(data)
      setSummaries(data.report.summaries)
      setIsLoading(false)
    })
  }
  function getRankColorFromNum(rank) {
    try {
      var color = ''
      switch (rank) {
        case 1:
          color = 'red'
          break
        case 2:
          color = 'orange'
          break
        case 3:
          color = 'yellow'
          break
        default:
          color = 'green'
          break
      }
      return color
    } catch (exception) {}
  }
  useEffect(() => {
    if (report) {
      fetchPreviewInfo(report.id)
    }
  }, [report])

  const getReport = () => {
    return detail?.report
  }

  const getSummaries = () => {
    return summaries
  }

  const getText = (point) => {
    var option = point.options.find((o) => o.id == point.result.option)
    if (option === null) {
      return ''
    }
    if (!option.children || !point.result.additionalValues || point.result.additionalValues === '') {
      return option.title
    }
    var options = point.result.additionalValues.split(',')
    return uniq(option.children.filter((o) => options.indexOf(o.id) >= 0).map((o) => o.title)).join(',') ?? option?.title
  }

  const getRiskLevel = (point) => {
    var option = point.options.find((o) => o.id == point.result.option)
    if (option == null) {
      return ''
    }
    if (!option.children || !point.result.additionalValues || point.result.additionalValues === '') {
      return option.riskLevel
    }
    var options = point.result.additionalValues.split(',')
    return uniq(option.children.filter((o) => options.indexOf(o.id) >= 0).map((o) => o.riskLevel)).join(',') ?? option?.riskLevel
  }

  useImperativeHandle(ref, () => ({
    getReport,
    getSummaries,
  }))

  return (
    <>
      {isLoading && (
        <div style={{ backgroundColor: '#fff', width: '100%', height: '100%' }}>
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translateX(-50%) translateY(-50%)',
              zIndex: 9999,
            }}
          >
            <LoadingIndicator type='radial' sizeClass='medium' determinate={false} color='blue' />
          </div>
        </div>
      )}
      <div style={{ visibility: isLoading ? 'hidden' : 'visible' }} className='total-report'>
        <div className='cover' style={{ textAlign: 'left' }}>
          <img src='/ABB-logo.svg' style={{ width: 120, float: 'right', clear: 'both', display: 'block' }} />
          <div style={{ height: 8, width: 52, background: 'red', clear: 'both', marginBottom: 32 }}></div>
          <h1 style={{ clear: 'both' }}>
            <span data-project='customerCompanyName'>{detail?.project?.name}</span>
          </h1>
          <h2>
            <span data-switchingroom='name'>{detail?.station?.name}</span>
          </h2>
          <h2>
            <span data-device='name'>无忧维保报告_总报告</span>
          </h2>
          <div style={{ textAlign: 'left', flexDirection: 'row', display: 'flex' }}>
            <dl style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
              <dt>报告状态：</dt>
              <dd>{totalStatus.find((s) => s.key === detail?.report?.status)?.title}</dd>
              {detail?.report?.status < 0 && (
                <>
                  <dt style={{ marginTop: 16 }}>{totalStatusText.find((s) => s.key === detail?.report?.status)?.title}</dt>
                  <dd>{detail?.report?.suggestion}</dd>
                </>
              )}
            </dl>
            <img src='/templates/TotalReportCover.png' alt='cover' style={{ clear: 'both', margin: 'auto', height: 360 }} />
          </div>
          <p>厦门ABB开关有限公司</p>
        </div>
        <div className='summary'>
          <div style={{ height: 8, width: 52, background: 'red', clear: 'both', marginBottom: 32 }}></div>
          <h2 style={{ textAlign: 'left' }}>报告摘要</h2>
          <h4 style={{ textAlign: 'left', marginTop: 16 }}>1 ) 维保设备范围</h4>
          <table
            style={{
              width: '100%',
              tableLayout: 'fixed',
              border: '2px double black',
              borderCollapse: 'collapse',
              borderRight: 'none',
              borderLeft: 'none',
            }}
          >
            <tr style={{ fontWeight: 'normal' }}>
              <td style={{ width: '5%', borderRight: '0.5px solid black' }}>序号</td>
              <td style={{ width: '30%', borderRight: '0.5px solid black' }}>配电室名称</td>
              <td style={{ width: '15%', borderRight: '0.5px solid black' }}>额定电压</td>
              <td style={{ width: '17%', borderRight: '0.5px solid black' }}>设备型号</td>
              <td style={{ width: '17%', borderRight: '0.5px solid black' }}>设备运行年限（年）</td>
              <td style={{ width: '17%' }}>数量（台）</td>
            </tr>
            {detail?.deviceSummaries?.map((d, i) => {
              return (
                <tr key={nanoid()} style={{ borderTop: '0.5px solid black' }}>
                  <td style={{ borderRight: '0.5px solid black' }}>{i + 1}</td>
                  <td style={{ borderRight: '0.5px solid black' }}>{d.name}</td>
                  <td style={{ borderRight: '0.5px solid black' }}>{d.voltage}%</td>
                  <td style={{ borderRight: '0.5px solid black' }}>{d.modal}</td>
                  <td style={{ borderRight: '0.5px solid black' }}>{d.years}</td>
                  <td>{d.count}</td>
                </tr>
              )
            })}
          </table>
          <div style={{ marginTop: 16 }}>
            本次维保作业按照ABB{detail?.data?.devices?.map((d) => ` <${d.name}> `)?.join('及')}的维护保养作业流程执行。
          </div>
          <h4 style={{ textAlign: 'left', marginTop: 16 }}>2） 维保作业安全</h4>
          <div style={{ marginTop: 16 }}>
            本次现场维护保养作业完全遵守公司和ABB相关的安全作业要求，严格执行相关安全作业规程，整个作业过程中未发生任何未遂及以上的安全事故。
          </div>
          <h4 style={{ textAlign: 'left', marginTop: 16 }}>3） 设备健康指数</h4>
          <div style={{ marginTop: 16 }}>
            经过评估，{detail?.station?.name}的{detail?.deviceSummaries[0]?.name}开关设备，设备重要性指数为{formatter.format(detail?.deviceCharData?.importance)}
            ，维保前设备健康指数为{formatter.format(detail?.deviceCharData?.health)}，处于{detail?.deviceCharData.healthPlace.name}。通过无忧维保，设备健康指数提升到
            {formatter.format(detail?.deviceAfterCharData.health)}，处于{detail?.deviceAfterCharData.healthPlace.name}。
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <ScatterChart
                data={[{ value: [detail?.deviceCharData?.health*100, detail?.deviceCharData?.importance*100], type: 'circle' }]}
                style={{ width: '100%', height: '360px' }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <ScatterChart
                data={[{ value: [detail?.deviceAfterCharData?.health*100, detail?.deviceAfterCharData?.importance*100], type: 'circle' }]}
                style={{ width: '100%', height: '360px' }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', borderTop: 'solid 0.5px', borderBottom: 'solid 0.5px' }}>
            <div style={{ flex: 1, display: 'flex' }}>
              <div style={{ flex: 1 }}>
                维保前
                <br />
                设备健康指数
              </div>
              <div style={{ flex: 1 }}>{formatter.format(detail?.deviceCharData?.health)}</div>
            </div>
            <div style={{ flex: 1 }}>
              <div style={{ flex: 1, display: 'flex' }}>
                <div style={{ flex: 1 }}>
                  维保后
                  <br />
                  设备健康指数
                </div>
                <div style={{ flex: 1 }}>{formatter.format(detail?.deviceAfterCharData?.health)}</div>
              </div>
            </div>
          </div>
          <h4 style={{ textAlign: 'left', marginTop: 16 }}>4） 发现问题小结</h4>
          <div
            style={{
              marginTop: 16,
              borderTop: 'double 2px',
              borderBottom: 'double 2px',
              backgroundColor: '#f2f2f2',
              fontSize: 16,
              padding: 4,
              fontWeight: 'bold',
            }}
          >
            维保前缺陷及隐患数量
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1, backgroundColor: '#f2f2f2' }}>
              <PieChart
                data={detail?.defects?.map((d) => {
                  return { name: d.name, value: d.count }
                })}
                style={{ width: '100%', height: '240px' }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <table style={{ width: '100%', margin: 0, textAlign: 'center' }}>
                <tr>
                  <td colSpan={2} style={{ width: '66%', padding: 8, borderRight: 'dotted 1px', borderBottom: 'dotted 1px' }}>
                    问题等级
                  </td>
                  <td style={{ width: '33%', padding: 8, borderBottom: 'dotted 1px' }}>数量</td>
                </tr>
                {detail?.defects?.map((d) => {
                  return (
                    <tr key={nanoid()}>
                      <td style={{ width: '33%', padding: 8, borderRight: 'dotted 1px', borderBottom: 'dotted 1px' }}>{d.name}</td>
                      <td style={{ width: '33%', padding: 8, borderRight: 'dotted 1px', borderBottom: 'dotted 1px' }}>{d.description}</td>
                      <td style={{ width: '33%', padding: 8, borderBottom: 'dotted 1px' }}>{d.count}</td>
                    </tr>
                  )
                })}
                <tr>
                  <td colSpan={2} style={{ width: '66%', padding: 8, borderRight: 'dotted 1px' }}>
                    缺陷及隐患总数
                  </td>
                  <td style={{ width: '33%', padding: 8 }}>{reduce(detail?.defects, (s, n) => s + n.count, 0)}</td>
                </tr>
              </table>
            </div>
          </div>
          <div
            style={{
              borderTop: 'double 2px',
              borderBottom: 'double 2px',
              backgroundColor: '#f2f2f2',
              fontSize: 16,
              padding: 4,
              fontWeight: 'bold',
            }}
          >
            维保后缺陷及隐患数量
          </div>
          <div style={{ display: 'flex', borderBottom: 'double 2px' }}>
            <div style={{ flex: 1, backgroundColor: '#f2f2f2' }}>
              <PieChart
                data={detail?.afterDefects?.map((d) => {
                  return { name: d.name, value: d.count }
                })}
                style={{ width: '100%', height: '240px' }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <table style={{ width: '100%', margin: 0, textAlign: 'center' }}>
                <tr>
                  <td colSpan={2} style={{ width: '66%', padding: 8, borderRight: 'dotted 1px', borderBottom: 'dotted 1px' }}>
                    问题等级
                  </td>
                  <td style={{ width: '33%', padding: 8, borderBottom: 'dotted 1px' }}>数量</td>
                </tr>
                {detail?.afterDefects?.map((d) => {
                  return (
                    <tr key={nanoid()}>
                      <td style={{ width: '33%', padding: 8, borderRight: 'dotted 1px', borderBottom: 'dotted 1px' }}>{d.name}</td>
                      <td style={{ width: '33%', padding: 8, borderRight: 'dotted 1px', borderBottom: 'dotted 1px' }}>{d.description}</td>
                      <td style={{ width: '33%', padding: 8, borderBottom: 'dotted 1px' }}>{d.count}</td>
                    </tr>
                  )
                })}
                <tr>
                  <td colSpan={2} style={{ width: '66%', padding: 8, borderRight: 'dotted 1px' }}>
                    缺陷及隐患总数
                  </td>
                  <td style={{ width: '33%', padding: 8 }}>{reduce(detail?.afterDefects, (s, n) => s + n.count, 0)}</td>
                </tr>
              </table>
            </div>
          </div>
          <ul style={{ marginTop: 16 }}>
            <li>
              <h4>发现的主要问题：</h4>
              <textarea
                id='SummaryDiscovery'
                value={summaries?.SummaryDiscovery}
                readOnly={readonly}
                onChange={(e) => setSummaries({ ...summaries, SummaryDiscovery: e.target.value })}
                name='SummaryDiscovery'
                className='k-textbox'
                rows='4'
              ></textarea>
            </li>
            <li>
              <h4>维保后主要遗留问题</h4>
              <textarea
                id='UndealedProblem'
                value={summaries?.UndealedProblem}
                readOnly={readonly}
                onChange={(e) => setSummaries({ ...summaries, UndealedProblem: e.target.value })}
                name='UndealedProblem'
                className='k-textbox'
                rows='4'
              ></textarea>
            </li>
            <li>
              <h4>5） 建议措施:</h4>
              <textarea
                id='CabinetWorkOtherAdvice'
                value={summaries?.CabinetWorkOtherAdvice}
                readOnly={readonly}
                onChange={(e) => setSummaries({ ...summaries, CabinetWorkOtherAdvice: e.target.value })}
                name='CabinetWorkOtherAdvice'
                className='k-textbox'
                rows='4'
              ></textarea>
            </li>
            <li>
              <h4>5） 维保总结:</h4>
              <textarea
                id='SummaryConclusion'
                value={summaries?.SummaryConclusion}
                readOnly={readonly}
                onChange={(e) => setSummaries({ ...summaries, SummaryConclusion: e.target.value })}
                name='SummaryConclusion'
                className='k-textbox'
                rows='4'
              ></textarea>
            </li>
          </ul>
        </div>
        <div className='summary'>
          <div style={{ height: 8, width: 52, background: 'red', clear: 'both', marginBottom: 32 }}></div>
          <h2 style={{ textAlign: 'left' }}>项目执行信息</h2>
          <div
            style={{
              borderTop: 'double 2px',
              borderBottom: 'double 2px',
              backgroundColor: '#f2f2f2',
              fontSize: 16,
              padding: 4,
              fontWeight: 'bold',
            }}
          >
            维保执行团队
          </div>
          <table style={{ width: '100%', margin: 0, textAlign: 'center' }}>
            <tr>
              <td style={{ width: '20%', padding: 8, borderRight: 'solid 0.5px', borderBottom: 'solid 0.5px' }}>项目经理</td>
              <td style={{ width: '80%', padding: 8, borderBottom: 'solid 0.5px' }}>{detail?.project?.team?.manager?.name}</td>
            </tr>
            <tr>
              <td style={{ width: '20%', padding: 8, borderRight: 'solid 0.5px', borderBottom: 'solid 0.5px' }}>联系电话</td>
              <td style={{ width: '80%', padding: 8, borderBottom: 'solid 0.5px' }}>{detail?.project?.team?.manager?.phoneNumber}</td>
            </tr>
            <tr>
              <td style={{ width: '20%', padding: 8, borderRight: 'solid 0.5px', borderBottom: 'solid 0.5px' }}>邮箱地址</td>
              <td style={{ width: '80%', padding: 8, borderBottom: 'solid 0.5px' }}>{detail?.project?.team?.manager?.email}</td>
            </tr>
            <tr>
              <td style={{ width: '20%', padding: 8, borderRight: 'solid 0.5px', borderBottom: 'solid 0.5px' }}>安全监督员</td>
              <td style={{ width: '80%', padding: 8, borderBottom: 'solid 0.5px' }}>
                {detail?.project?.team?.members?.find((m) => m.role === 'safety')?.name}
              </td>
            </tr>
            <tr>
              <td style={{ width: '20%', padding: 8, borderRight: 'solid 0.5px' }}>维保团队成员</td>
              <td style={{ width: '80%', padding: 8 }}>
                {uniq(detail?.project?.team?.members?.filter((m) => m.role !== 'safety').map((m) => m.name)).join(',')}
              </td>
            </tr>
          </table>
          <div
            style={{
              borderTop: 'double 2px',
              borderBottom: 'double 2px',
              backgroundColor: '#f2f2f2',
              fontSize: 16,
              padding: 4,
              fontWeight: 'bold',
            }}
          >
            维保执行时间
          </div>
          <table style={{ width: '100%', margin: 0, textAlign: 'center' }}>
            <tr>
              <td style={{ width: '20%', padding: 8, borderRight: 'solid 0.5px', borderBottom: 'solid 0.5px' }}>维保作业开始日期</td>
              <td style={{ width: '80%', padding: 8, borderBottom: 'solid 0.5px' }}>{moment(detail?.project?.startDate).format('yyyy-MM-DD')}</td>
            </tr>
            <tr>
              <td style={{ width: '20%', padding: 8, borderRight: 'solid 0.5px' }}>维保作业结束日期</td>
              <td style={{ width: '80%', padding: 8 }}>{moment(detail?.project?.endDate).format('yyyy-MM-DD')}</td>
            </tr>
          </table>
          <div
            style={{
              borderTop: 'double 2px',
              borderBottom: 'double 2px',
              backgroundColor: '#f2f2f2',
              fontSize: 16,
              padding: 4,
              fontWeight: 'bold',
            }}
          >
            各设备维保完成时间
          </div>
          <table style={{ width: '100%', margin: 0, textAlign: 'center' }}>
            <tr>
              <td style={{ width: '5%', padding: 8, borderRight: 'solid 0.5px' }}>序号</td>
              <td style={{ width: '20%', padding: 8, borderRight: 'solid 0.5px' }}>设备名称</td>
              <td style={{ width: '20%', padding: 8, borderRight: 'solid 0.5px' }}>设备编号</td>
              <td style={{ width: '20%', padding: 8, borderRight: 'solid 0.5px' }}>设备型号</td>
              <td style={{ width: '15%', padding: 8, borderRight: 'solid 0.5px' }}>设备生产时间</td>
              <td style={{ width: '20%', padding: 8 }}>维保完成时间</td>
            </tr>
            {detail?.data?.devices
              ?.flatMap((d) => d.devices)
              .filter((d) => !!d)
              .map((d, i) => {
                return (
                  <tr>
                    <td style={{ width: '5%', padding: 8, borderRight: 'solid 0.5px', borderTop: 'solid 0.5px' }}>{i + 1}</td>
                    <td style={{ width: '20%', padding: 8, borderRight: 'solid 0.5px', borderTop: 'solid 0.5px' }}>{d.device.displayName}</td>
                    <td style={{ width: '20%', padding: 8, borderRight: 'solid 0.5px', borderTop: 'solid 0.5px' }}>{d.device.sn}</td>
                    <td style={{ width: '20%', padding: 8, borderRight: 'solid 0.5px', borderTop: 'solid 0.5px' }}>{d.device.modal}</td>
                    <td style={{ width: '15%', padding: 8, borderRight: 'solid 0.5px', borderTop: 'solid 0.5px' }}>
                      {moment(d.device.productDate).format('yyyy-MM-DD')}
                    </td>
                    <td style={{ width: '20%', padding: 8, borderTop: 'solid 0.5px' }}>{moment(d.report.creationTime).format('yyyy-MM-DD')}</td>
                  </tr>
                )
              })}
          </table>
          <div
            style={{
              borderTop: 'double 2px',
              borderBottom: 'double 2px',
              backgroundColor: '#f2f2f2',
              fontSize: 16,
              padding: 4,
              fontWeight: 'bold',
            }}
          >
            维保花絮
          </div>
          <table style={{ width: '100%', margin: 0, textAlign: 'center', borderBottom: 'double 2px' }}>
            <tr>
              <td style={{ width: '20%', padding: 8, borderRight: 'solid 0.5px', borderBottom: 'solid 0.5px' }}>配电室一览</td>
              <td style={{ width: '80%', padding: 8, borderBottom: 'solid 0.5px' }}>
                <Image id={detail?.station?.pictures['1']} style={{ maxWidth: 230, maxHeight: 230 }} />
              </td>
            </tr>
            <tr>
              <td style={{ width: '20%', padding: 8, borderRight: 'solid 0.5px', borderBottom: 'solid 0.5px' }}>维保过程</td>
              <td style={{ width: '80%', padding: 8, borderBottom: 'solid 0.5px' }}>
                {' '}
                <Image id={detail?.station?.pictures['2']} style={{ maxWidth: 230, maxHeight: 230 }} />
              </td>
            </tr>
            <tr>
              <td style={{ width: '20%', padding: 8, borderRight: 'solid 0.5px' }}>维保后</td>
              <td style={{ width: '80%', padding: 8 }}>
                {' '}
                <Image id={detail?.station?.pictures['3']} style={{ maxWidth: 230, maxHeight: 230 }} />
              </td>
            </tr>
          </table>
        </div>
        <div className='summary'>
          <div style={{ height: 8, width: 52, background: 'red', clear: 'both', marginBottom: 32 }}></div>
          <h2 style={{ textAlign: 'left' }}>维保信息汇总</h2>
          {detail?.station?.parameters?.map((item) => {
            return (
              <>
                <h3 style={{ marginTop: 28, marginBottom: 16 }}>{item.name}</h3>
                <table style={{ width: '100%', margin: 0, textAlign: 'center', borderTop: 'double 2px', borderBottom: 'double 2px' }}>
                  <tr>
                    <td style={{ width: '5%', padding: 8, borderRight: 'solid 0.5px' }}>序号</td>
                    <td colSpan={2} style={{ width: '20%', padding: 8, borderRight: 'solid 0.5px' }}>
                      检查项目
                    </td>
                    <td style={{ width: '20%', padding: 8, borderRight: 'solid 0.5px' }}>检查结果</td>
                    <td style={{ width: '20%', padding: 8, borderRight: 'solid 0.5px' }}>风险等级</td>
                    <td style={{ width: '15%', padding: 8 }}>备注</td>
                  </tr>
                  {item.datas.map((c, i) => {
                    console.log(c)
                    return Object.entries(c.parameters).map((p, ii) => {
                      return (
                        <tr>
                          <td style={{ width: '5%', padding: 8, borderTop: 'solid 0.5px', borderRight: 'solid 0.5px' }}>{`${i + 1}.${ii + 1}`}</td>
                          {ii === 0 && (
                            <td
                              rowSpan={Object.keys(c.parameters).length}
                              style={{ width: '20%', borderTop: 'solid 0.5px', padding: 8, borderRight: 'solid 0.5px' }}
                            >
                              {c.name}
                            </td>
                          )}
                          <td style={{ width: '20%', borderTop: 'solid 0.5px', padding: 8, borderRight: 'solid 0.5px' }}>{p[0]}</td>
                          <td style={{ width: '20%', padding: 8, borderTop: 'solid 0.5px', borderRight: 'solid 0.5px' }}>{getText(p[1])}</td>
                          <td style={{ width: '20%', padding: 8, borderTop: 'solid 0.5px', borderRight: 'solid 0.5px' }}>{getRiskLevel(p[1])}</td>
                          <td style={{ width: '15%', padding: 8, borderTop: 'solid 0.5px' }}>{p[1].description}</td>
                        </tr>
                      )
                    })
                  })}
                </table>
              </>
            )
          })}
          <h3 style={{ marginTop: 28, marginBottom: 16 }}>维保前设备健康评估</h3>
          <div
            style={{
              borderTop: 'double 2px',
              borderBottom: 'double 2px',
              backgroundColor: '#f2f2f2',
              fontSize: 16,
              padding: 4,
              fontWeight: 'bold',
            }}
          >
            {detail?.station?.name}设备健康指数
          </div>
          <div style={{ display: 'flex', padding: 0, margin: 0 }}>
            <div style={{ width: '50%', padding: 16, height: 280, flex: 1 }}>
              <ScatterChart
                data={[{ value: [detail?.deviceCharData?.health*100, detail?.deviceCharData?.importance*100], type: 'circle' }]}
                width={'100%'}
                style={{ width: '100%', height: '280px' }}
              />
            </div>
            <div style={{ width: '50%', padding: 0, height: 280, flex: 1 }}>
              <table style={{ border: 'none', width: '100%', margin: 0 }}>
                {detail?.stationSummaries.map((s, i) => {
                  return (
                    <tr key={nanoid()}>
                      <td style={{ padding: 4, borderTop: i === 0 ? 'none' : '0.5px solid black' }}>{s.name}</td>
                      <td style={{ padding: 4, borderTop: i === 0 ? 'none' : '0.5px solid black', borderLeft: '0.5px solid black' }}>{s.unit}</td>
                      <td style={{ padding: 4, borderTop: i === 0 ? 'none' : '0.5px solid black', borderLeft: '0.5px solid black' }}>{s.value}</td>
                    </tr>
                  )
                })}
              </table>
            </div>
          </div>
          <div style={{ display: 'flex', padding: 0, margin: 0, borderTop: '0.5px solid black' }}>
            <div style={{ padding: 16, height: 56, flex: 0.5 }}>
              设备整体
              <br />
              风险指数
            </div>
            <div style={{ padding: 16, height: 56, flex: 1, fontSize: 24, color: 'red' }}>{formatter.format(detail?.deviceCharData?.health)}</div>
            <div style={{ padding: 16, height: 56, flex: 0.5 }}>
              设备整体
              <br />
              重要性指数
            </div>
            <div style={{ padding: 16, height: 56, flex: 1, fontSize: 24, color: 'green' }}>{formatter.format(detail?.deviceCharData?.importance)}</div>
          </div>
          <h4 style={{ marginTop: 16, borderTop: '2px double black', borderBottom: '2px double black', backgroundColor: '#f2f2f2', padding: 8 }}>
            单体设备健康指数
          </h4>
          <table
            style={{
              width: '100%',
              margin: 0,
              tableLayout: 'fixed',
              borderBottom: 'double 2px',
            }}
          >
            <tr style={{ fontWeight: 'normal' }}>
              <td key={nanoid()} style={{ width: '5%', borderRight: '0.5px solid black' }}>
                序号
              </td>
              <td style={{ textAlign: 'center', padding: 4, width: '30%', borderRight: '0.5px solid black' }}>设备名称（线路名称）</td>
              <td style={{ textAlign: 'center', padding: 4, width: '17%', borderRight: '0.5px solid black' }}>设备型号</td>
              <td style={{ textAlign: 'center', padding: 4, width: '17%', borderRight: '0.5px solid black' }}>设备重要性指数</td>
              <td colSpan={2} style={{ textAlign: 'center', padding: 4, width: '30%' }}>
                设备健康指数
              </td>
            </tr>
            {detail?.data.devices
              ?.flatMap((d) => d.devices)
              ?.map((d, i) => {
                return (
                  <tr key={nanoid()}>
                    <td style={{ textAlign: 'center', padding: 4, borderTop: '0.5px solid black', borderRight: '0.5px solid black' }}>{i + 1}</td>
                    <td style={{ textAlign: 'center', padding: 4, borderTop: '0.5px solid black', borderRight: '0.5px solid black' }}>
                      {d.device?.displayName}
                    </td>
                    <td style={{ textAlign: 'center', padding: 4, borderTop: '0.5px solid black', borderRight: '0.5px solid black' }}>
                      {d.device.modal}%
                    </td>
                    <td
                      style={{ textAlign: 'center', padding: 4, borderTop: '0.5px solid black', borderRight: '0.5px solid black' }}
                    >{`${d?.beforeChart?.importance}%`}</td>
                    <td
                      style={{
                        textAlign: 'center',
                        padding: 4,
                        borderTop: '0.5px solid black',
                        tableLayout: 'fixed',
                        width: '20px',
                        borderRight: '0.5px solid black',
                      }}
                    >{`${d?.beforeChart?.health?.toFixed(1)}%`}</td>
                    <td style={{ textAlign: 'center', padding: 4, width: '30%', borderTop: '0.5px solid black' }}>
                      {d?.beforeChart?.healthPlace?.name}
                    </td>
                  </tr>
                )
              })}
          </table>

          <h3 style={{ marginTop: 28, marginBottom: 16 }}>维保后设备健康评估</h3>
          <div
            style={{
              borderTop: 'double 2px',
              borderBottom: 'double 2px',
              backgroundColor: '#f2f2f2',
              fontSize: 16,
              padding: 4,
              fontWeight: 'bold',
            }}
          >
            {detail?.station?.name}设备健康指数
          </div>
          <div style={{ display: 'flex', padding: 0, margin: 0 }}>
            <div style={{ width: '50%', padding: 16, height: 280, flex: 1 }}>
              <ScatterChart
                data={[{ value: [detail?.deviceAfterCharData?.health*100, detail?.deviceAfterCharData?.importance*100], type: 'circle' }]}
                width={'100%'}
                style={{ width: '100%', height: '280px' }}
              />
            </div>
            <div style={{ width: '50%', padding: 0, height: 280, flex: 1 }}>
              <table style={{ border: 'none', width: '100%', margin: 0 }}>
                {detail?.stationSummaries.map((s, i) => {
                  return (
                    <tr key={nanoid()}>
                      <td style={{ padding: 4, borderTop: i === 0 ? 'none' : '0.5px solid black' }}>{s.name}</td>
                      <td style={{ padding: 4, borderTop: i === 0 ? 'none' : '0.5px solid black', borderLeft: '0.5px solid black' }}>{s.unit}</td>
                      <td style={{ padding: 4, borderTop: i === 0 ? 'none' : '0.5px solid black', borderLeft: '0.5px solid black' }}>{s.value}</td>
                    </tr>
                  )
                })}
              </table>
            </div>
          </div>
          <div style={{ display: 'flex', padding: 0, margin: 0, borderTop: '0.5px solid black' }}>
            <div style={{ padding: 16, height: 56, flex: 0.5 }}>
              设备整体
              <br />
              风险指数
            </div>
            <div style={{ padding: 16, height: 56, flex: 1, fontSize: 24, color: 'red' }}>{formatter.format(detail?.deviceAfterCharData?.health)}</div>
            <div style={{ padding: 16, height: 56, flex: 0.5 }}>
              设备整体
              <br />
              重要性指数
            </div>
            <div style={{ padding: 16, height: 56, flex: 1, fontSize: 24, color: 'green' }}>
              {formatter.format(detail?.deviceAfterCharData?.importance)}
            </div>
          </div>
          <h4 style={{ marginTop: 16, borderTop: '2px double black', borderBottom: '2px double black', backgroundColor: '#f2f2f2', padding: 8 }}>
            单体设备健康指数
          </h4>
          <table
            style={{
              width: '100%',
              margin: 0,
              tableLayout: 'fixed',
              borderBottom: 'double 2px',
            }}
          >
            <tr style={{ fontWeight: 'normal' }}>
              <td key={nanoid()} style={{ width: '5%', borderRight: '0.5px solid black' }}>
                序号
              </td>
              <td style={{ textAlign: 'center', padding: 4, width: '30%', borderRight: '0.5px solid black' }}>设备名称（线路名称）</td>
              <td style={{ textAlign: 'center', padding: 4, width: '17%', borderRight: '0.5px solid black' }}>设备型号</td>
              <td style={{ textAlign: 'center', padding: 4, width: '17%', borderRight: '0.5px solid black' }}>设备重要性指数</td>
              <td colSpan={2} style={{ textAlign: 'center', padding: 4, width: '30%' }}>
                设备健康指数
              </td>
            </tr>
            {detail?.data.devices
              ?.flatMap((d) => d.devices)
              ?.map((d, i) => {
                return (
                  <tr key={nanoid()}>
                    <td style={{ textAlign: 'center', padding: 4, borderTop: '0.5px solid black', borderRight: '0.5px solid black' }}>{i + 1}</td>
                    <td style={{ textAlign: 'center', padding: 4, borderTop: '0.5px solid black', borderRight: '0.5px solid black' }}>
                      {d.device?.displayName}
                    </td>
                    <td style={{ textAlign: 'center', padding: 4, borderTop: '0.5px solid black', borderRight: '0.5px solid black' }}>
                      {d.device.modal}%
                    </td>
                    <td
                      style={{ textAlign: 'center', padding: 4, borderTop: '0.5px solid black', borderRight: '0.5px solid black' }}
                    >{formatter.format(d?.afterChart?.importance)}</td>
                    <td
                      style={{
                        textAlign: 'center',
                        padding: 4,
                        borderTop: '0.5px solid black',
                        tableLayout: 'fixed',
                        width: '20px',
                        borderRight: '0.5px solid black',
                      }}
                    >{formatter.format(d?.afterChart?.health)}</td>
                    <td style={{ textAlign: 'center', padding: 4, width: '30%', borderTop: '0.5px solid black' }}>
                      {d?.afterChart?.healthPlace?.name}
                    </td>
                  </tr>
                )
              })}
          </table>
        </div>
        <div className='summary'>
          <div style={{ height: 8, width: 52, background: 'red', clear: 'both', marginBottom: 32 }}></div>
          <h2 style={{ textAlign: 'left' }}>维保总结</h2>
          <h4 style={{ marginTop: 0, borderTop: '2px double black', borderBottom: '2px double black', backgroundColor: '#f2f2f2', padding: 8 }}>
            主要问题
          </h4>
          <table
            style={{
              width: '100%',
              margin: 0,
              tableLayout: 'fixed',
              borderBottom: '2px double black',
            }}
          >
            <tr style={{ fontWeight: 'normal' }}>
              <td key={nanoid()} style={{ width: '5%', borderRight: '0.5px solid black' }}>
                序号
              </td>
              <td style={{ textAlign: 'center', padding: 4, width: '40%' }}>问题图片</td>
              <td style={{ textAlign: 'center', padding: 4, width: '55%' }}>问题说明及处理</td>
            </tr>
            {uniqBy(
              detail?.data.devices
                ?.flatMap((d) => d.devices)
                .flatMap((d) => d.data)
                .flatMap((d) => d.result.checkItems)
                .flatMap((c) => c.points)
                .filter((o) => o.result.code === '2'),
              'id'
            )?.map((d, i) => {
              console.log(d)
              return (
                <tr key={nanoid()}>
                  <td style={{ textAlign: 'center', padding: 4, borderTop: '0.5px solid black', borderRight: '0.5px solid black' }}>{i + 1}</td>
                  <td style={{ textAlign: 'center', padding: 4, borderTop: '0.5px solid black' }}>
                    <Image id={d.result.beforPhoto} style={{ maxHeight: 140 }} />
                  </td>
                  <td style={{ textAlign: 'center', padding: 0, borderTop: '0.5px solid black' }}>
                    <table style={{ border: 'none', margin: 0 }}>
                      <tr>
                        <td style={{ padding: 4, width: '20%', borderRight: 'solid 0.5px', borderBottom: 'solid 0.5px' }}>风险等级</td>
                        <td style={{ padding: 4, width: '30%', borderRight: 'solid 0.5px', borderBottom: 'solid 0.5px' }}>{getRiskLevel(d)}</td>
                        <td style={{ padding: 4, width: '20%', borderRight: 'solid 0.5px', borderBottom: 'solid 0.5px' }}>处理结果</td>
                        <td style={{ padding: 4, width: '30%', borderBottom: 'solid 0.5px' }}>{d.result.result === 1 ? '已处理' : '未处理'}</td>
                      </tr>
                      <tr>
                        <td style={{ padding: 4, width: '20%', borderRight: 'solid 0.5px', borderBottom: 'solid 0.5px' }}>问题描述</td>
                        <td colSpan={3} style={{ padding: 4, width: '80%', height: 138, borderBottom: 'solid 0.5px' }}>
                          {d.result.description}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: 4, width: '20%', borderRight: 'solid 0.5px' }}>问题描述</td>
                        <td colSpan={3} style={{ padding: 4, width: '80%' }}>
                          {d.device}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              )
            })}
          </table>
          <ul style={{ marginTop: 16 }}>
            <li>
              <h4>小结:</h4>
              <textarea
                id='DeviceProblemSummaryRemark'
                value={summaries?.DeviceProblemSummaryRemark}
                readOnly={readonly}
                onChange={(e) => setSummaries({ ...summaries, DeviceProblemSummaryRemark: e.target.value })}
                name='DeviceProblemSummaryRemark'
                className='k-textbox'
                rows='4'
              ></textarea>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
})
