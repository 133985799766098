import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react'
import { Checkbox, Dialog, LoadingIndicator } from '@abb/abb-common-ux-react'
import { getReportDetail, getTotalReportDetail } from 'common/apis/totalreports'
import '../../../../styles/totalReport.scss'

import axios from 'axios'
import moment from 'moment'
import { Image } from 'components/Image'
import envs from './enviroment.json'
import { useEffect } from 'react'
import * as echarts from 'echarts'
import { groupBy, sumBy, uniq, uniqBy } from 'lodash'
import { tr } from 'date-fns/locale'
import { nanoid } from 'nanoid'
import { ScatterChart } from './ScatterChart'

export const TotalReport = forwardRef(({ report, readonly = false }, ref) => {
  const [detail, setDetail] = useState(null)
  const [summaries, setSummaries] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const formatter=new Intl.NumberFormat("en-US", {style: "percent",maximumFractionDigits: 1})
  const totalStatus = [
    { key: -2, title: '已退回' },
    { key: -1, title: '已驳回' },
    { key: 0, title: '待编辑' },
    { key: 1, title: '待审核' },
    { key: 2, title: '待生成' },
    { key: 3, title: '生成中' },
    { key: 4, title: '已生成' },
    { key: 5, title: '等待生成' },
  ]
  const totalStatusText = [
    { key: -2, title: '退回意见' },
    { key: -1, title: '驳回意见' },
  ]
  let index = 0
  const fetchPreviewInfo = (reportId) => {
    setIsLoading(true)
    axios.get(getReportDetail(), { params: { id: reportId } }).then((response) => {
      const data = response.data
      setDetail(data)
      setSummaries(data.report.summaries)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if (report) {
      fetchPreviewInfo(report.id)
    }
  }, [report])

  const getReport = () => {
    return detail?.report
  }

  const getSummaries = () => {
    return summaries
  }

  const getText = (point) => {
    var option = point.options.find((o) => o.id == point.result.option)
    if (option === null) {
      return ''
    }
    if (!option.children || !point.result.additionalValues || point.result.additionalValues === '') {
      return option.title
    }
    var options = point.result.additionalValues.split(',').map((t) => t.toLowerCase())
    return uniq(option.children.filter((o) => options.indexOf(o.id.toLowerCase() >= 0)).map((o) => o.title)).join(',') ?? option?.title
  }

  useImperativeHandle(ref, () => ({
    getReport,
    getSummaries
  }))
  function getRankColorFromNum(rank) {
    try {
      var color = ''
      switch (rank) {
        case 1:
          color = 'red'
          break
        case 2:
          color = 'orange'
          break
        case 3:
          color = 'yellow'
          break
        default:
          color = 'green'
          break
      }
      return color
    } catch (exception) {}
  }
  return (
    <>
      {isLoading && (
        <div style={{ backgroundColor: '#fff', width: '100%', height: '100%' }}>
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translateX(-50%) translateY(-50%)',
              zIndex: 9999,
            }}
          >
            <LoadingIndicator type='radial' sizeClass='medium' determinate={false} color='blue' />
          </div>
        </div>
      )}
      <div style={{ visibility: isLoading ? 'hidden' : 'visible' }} className='total-report'>
        <div className='cover' style={{ textAlign: 'left' }}>
          <img src='/ABB-logo.svg' style={{ width: 120, float: 'right', clear: 'both', display: 'block' }} />
          <div style={{ height: 8, width: 52, background: 'red', clear: 'both', marginBottom: 32 }}></div>
          <h1 style={{ clear: 'both' }}>
            <span data-project='customerCompanyName'>{detail?.project?.name}</span>
          </h1>
          <h2>
            <span data-switchingroom='name'>{detail?.station?.name}</span>
          </h2>
          <h2>
            <span data-device='name'>{detail?.deviceTitle}</span>电气设备运行风险评估报告
          </h2>
          <div style={{ textAlign: 'left', flexDirection: 'row', display: 'flex' }}>
            <dl style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
              <dt>报告状态：</dt>
              <dd>{totalStatus.find((s) => s.key === detail?.report?.status)?.title}</dd>
              {detail?.report?.status < 0 && (
                <>
                  <dt style={{ marginTop: 16 }}>{totalStatusText.find((s) => s.key === detail?.report?.status)?.title}</dt>
                  <dd>{detail?.report?.suggestion}</dd>
                </>
              )}
            </dl>
            <img src='/templates/TotalReportCover.png' alt='cover' style={{ clear: 'both', margin: 'auto', height: 360 }} />
          </div>
          <p>厦门ABB开关有限公司</p>
        </div>
        <div className='summary'>
          <div style={{ height: 8, width: 52, background: 'red', clear: 'both', marginBottom: 32 }}></div>
          <h2 style={{ textAlign: 'left' }}>报告摘要</h2>
          <table
            style={{
              width: '100%',
              tableLayout: 'fixed',
              border: '2px double black',
              borderCollapse: 'collapse',
              borderRight: 'none',
              borderLeft: 'none',
            }}
          >
            <tr style={{ fontWeight: 'normal' }}>
              <td style={{ width: '5%', borderRight: '0.5px solid black' }}>序号</td>
              <td style={{ width: '30%', borderRight: '0.5px solid black' }}>配电室名称</td>
              <td style={{ width: '15%', borderRight: '0.5px solid black' }}>额定电压</td>
              <td style={{ width: '17%', borderRight: '0.5px solid black' }}>变电站重要性指数</td>
              <td style={{ width: '17%', borderRight: '0.5px solid black' }}>设备风险指数</td>
              <td style={{ width: '17%' }}>运行环境风险指数</td>
            </tr>
            {detail?.deviceSummaries?.map((d, i) => {
              return (
                <tr key={nanoid()} style={{ borderTop: '0.5px solid black' }}>
                  <td style={{ borderRight: '0.5px solid black' }}>{i + 1}</td>
                  <td style={{ borderRight: '0.5px solid black' }}>{d.name}</td>
                  <td style={{ borderRight: '0.5px solid black' }}>{d.voltage}%</td>
                  <td style={{ borderRight: '0.5px solid black' }}>{d.importance.toFixed(2)}%</td>
                  <td style={{ borderRight: '0.5px solid black' }}>{(100 - d.health).toFixed(2)}%</td>
                  <td>{d.environmental.toFixed(2)}%</td>
                </tr>
              )
            })}
          </table>
          <ul>
            <li>
              <textarea
                id='SummaryDiscovery'
                value={summaries?.SummaryDiscovery}
                readOnly={readonly}
                onChange={(e) => setSummaries({ ...summaries, SummaryDiscovery: e.target.value })}
                name='SummaryDiscovery'
                className='k-textbox'
                rows='4'
              ></textarea>
            </li>
            <li>
              <h4>ABB建议措施:</h4>
              <textarea
                id='CabinetWorkOtherAdvice'
                value={summaries?.CabinetWorkOtherAdvice}
                readOnly={readonly}
                onChange={(e) => setSummaries({ ...summaries, CabinetWorkOtherAdvice: e.target.value })}
                name='CabinetWorkOtherAdvice'
                className='k-textbox'
                rows='4'
              ></textarea>
            </li>
          </ul>
        </div>
        <div className='summary'>
          <div style={{ height: 8, width: 52, background: 'red', clear: 'both', marginBottom: 32 }}></div>
          <h2 style={{ textAlign: 'left' }}>项目信息</h2>
          <div style={{ marginTop: 16 }}>本项目需要评估的开关设备，设备信息如下。</div>
          <table
            style={{
              width: '100%',
              tableLayout: 'fixed',
              border: '2px double black',
              borderCollapse: 'collapse',
              borderRight: 'none',
              borderLeft: 'none',
            }}
          >
            <tr style={{ fontWeight: 'normal' }}>
              <td style={{ width: '5%', borderRight: '0.5px solid black' }}>序号</td>
              <td style={{ width: '30%', borderRight: '0.5px solid black' }}>配电室名称</td>
              <td style={{ width: '15%', borderRight: '0.5px solid black' }}>额定电压</td>
              <td style={{ width: '17%', borderRight: '0.5px solid black' }}>设备型号</td>
              <td style={{ width: '17%', borderRight: '0.5px solid black' }}>设备运行年限（年）</td>
              <td style={{ width: '17%' }}>数量（台）</td>
            </tr>
            {detail?.deviceSummaries?.map((d, i) => {
              return (
                <tr key={nanoid()} style={{ borderTop: '0.5px solid black' }}>
                  <td style={{ borderRight: '0.5px solid black' }}>{i + 1}</td>
                  <td style={{ borderRight: '0.5px solid black' }}>{d.name}</td>
                  <td style={{ borderRight: '0.5px solid black' }}>{d.voltage}</td>
                  <td style={{ borderRight: '0.5px solid black' }}>{d.modal}</td>
                  <td style={{ borderRight: '0.5px solid black' }}>{d.years}</td>
                  <td>{d.count}</td>
                </tr>
              )
            })}
          </table>
          <div style={{ marginTop: 16 }}>风险评估中，抽检的设备如下表。</div>
          <table
            style={{
              width: '100%',
              tableLayout: 'fixed',
              border: '2px double black',
              borderCollapse: 'collapse',
              borderRight: 'none',
              borderLeft: 'none',
            }}
          >
            <tr style={{ fontWeight: 'normal' }}>
              <td key={nanoid()} style={{ width: '5%', borderRight: '0.5px solid black' }}>
                序号
              </td>
              <td style={{ width: '30%', borderRight: '0.5px solid black' }}>配电室名称</td>
              <td style={{ width: '15%', borderRight: '0.5px solid black' }}>额定电压</td>
              <td style={{ width: '17%', borderRight: '0.5px solid black' }}>设备型号</td>
              <td style={{ width: '17%', borderRight: '0.5px solid black' }}>线路名称</td>
              <td style={{ width: '17%' }}>备注</td>
            </tr>
            {detail?.data.devices
              ?.flatMap((d) => d.devices)
              ?.map((d, i) => {
                return (
                  <tr key={nanoid()} style={{ borderTop: '0.5px solid black' }}>
                    <td style={{ borderRight: '0.5px solid black' }}>{i + 1}</td>
                    <td style={{ borderRight: '0.5px solid black' }}>{detail?.station?.name}</td>
                    <td style={{ borderRight: '0.5px solid black' }}>{d.device.parameters.find((p) => p.id == '1')?.value}%</td>
                    <td style={{ borderRight: '0.5px solid black' }}>{d.device.modal}</td>
                    <td style={{ borderRight: '0.5px solid black' }}>{d.device.pannelName}</td>
                    <td></td>
                  </tr>
                )
              })}
          </table>
        </div>
        <div className='summary'>
          <div style={{ height: 8, width: 52, background: 'red', clear: 'both', marginBottom: 32 }}></div>
          <h2 style={{ textAlign: 'left' }}>评估报告</h2>
          <h3 style={{ marginTop: 8 }}>{detail?.station?.name}设备风险评估总结</h3>
          <ul style={{ marginTop: 16 }}>
            <li>
              <h4>评估结论:</h4>
              <textarea
                id='SummaryConclusion'
                value={summaries?.SummaryConclusion}
                readOnly={readonly}
                onChange={(e) => setSummaries({ ...summaries, SummaryConclusion: e.target.value })}
                name='SummaryConclusion'
                className='k-textbox'
                rows='4'
              ></textarea>
            </li>
            <li>
              <div style={{ marginTop: 16 }}>总体风险指数图如下：</div>
              <ScatterChart
                data={[
                  { value: [ detail?.deviceCharData?.health*100, detail?.deviceCharData?.importance*100], type: 'circle' },
                  { value: [ detail?.environmentalCharData?.health*100, detail?.environmentalCharData?.importance*100], type: 'triangle' },
                ]}
                width={'100%'}
                style={{ width: '100%', height: '450px' }}
              />
            </li>
            <li>
              <textarea
                id='DeviceMaintenanceState'
                value={summaries?.DeviceMaintenanceState}
                readOnly={readonly}
                onChange={(e) => setSummaries({ ...summaries, DeviceMaintenanceState: e.target.value })}
                name='DeviceMaintenanceState'
                className='k-textbox'
                rows='4'
              ></textarea>
            </li>
            <li>
              <h4>建议措施:</h4>
              <textarea
                id='DeviceStateScan'
                value={summaries?.DeviceStateScan}
                readOnly={readonly}
                onChange={(e) => setSummaries({ ...summaries, DeviceStateScan: e.target.value })}
                name='DeviceStateScan'
                className='k-textbox'
                rows='4'
              ></textarea>
            </li>
          </ul>
          <h3 style={{ marginTop: 8 }}>{detail?.station?.name}设备风险指数</h3>
          <h4 style={{ marginTop: 16, borderTop: '2px double black', borderBottom: '2px double black', backgroundColor: '#f2f2f2', padding: 8 }}>
            {detail?.station?.name}设备风险指数
          </h4>
          <div style={{ display: 'flex', padding: 0, margin: 0 }}>
            <div style={{ width: '50%', padding: 16, height: 280, flex: 1 }}>
              <ScatterChart
                data={[{ value: [detail?.deviceCharData?.health*100, detail?.deviceCharData?.importance*100], type: 'circle' }]}
                width={'100%'}
                style={{ width: '100%', height: '280px' }}
              />
            </div>
            <div style={{ width: '50%', padding: 0, height: 280, flex: 1 }}>
              <table style={{ border: 'none', width: '100%', margin: 0 }}>
                {detail?.stationSummaries.map((s, i) => {
                  return (
                    <tr key={nanoid()}>
                      <td style={{ padding: 4, borderTop: i === 0 ? 'none' : '0.5px solid black' }}>{s.name}</td>
                      <td style={{ padding: 4, borderTop: i === 0 ? 'none' : '0.5px solid black', borderLeft: '0.5px solid black' }}>{s.unit}</td>
                      <td style={{ padding: 4, borderTop: i === 0 ? 'none' : '0.5px solid black', borderLeft: '0.5px solid black' }}>{s.value}</td>
                    </tr>
                  )
                })}
              </table>
            </div>
          </div>
          <div style={{ display: 'flex', padding: 0, margin: 0, borderTop: '0.5px solid black' }}>
            <div style={{ padding: 16, height: 56, flex: 0.5 }}>
              设备整体
              <br />
              风险指数
            </div>
            <div style={{ padding: 16, height: 56, flex: 1, fontSize: 24, color: 'red' }}>{formatter.format(detail?.deviceCharData?.health??0)}</div>
            <div style={{ padding: 16, height: 56, flex: 0.5 }}>
              设备整体
              <br />
              重要性指数
            </div>
            <div style={{ padding: 16, height: 56, flex: 1, fontSize: 24, color: 'green' }}>{formatter.format(detail?.deviceCharData?.importance)}</div>
          </div>
          <h4 style={{ marginTop: 16, borderTop: '2px double black', borderBottom: '2px double black', backgroundColor: '#f2f2f2', padding: 8 }}>
            {detail?.station?.name}设备风险指数
          </h4>
          <table
            style={{
              width: '100%',
              margin: 0,
              tableLayout: 'fixed',
            }}
          >
            <tr style={{ fontWeight: 'normal' }}>
              <td key={nanoid()} style={{ width: '5%', borderRight: '0.5px solid black' }}>
                序号
              </td>
              <td style={{ textAlign: 'center', padding: 4, width: '30%', borderRight: '0.5px solid black' }}>设备名称（线路名称）</td>
              <td style={{ textAlign: 'center', padding: 4, width: '17%', borderRight: '0.5px solid black' }}>设备型号</td>
              <td style={{ textAlign: 'center', padding: 4, width: '17%', borderRight: '0.5px solid black' }}>设备重要性指数</td>
              <td colSpan={2} style={{ textAlign: 'center', padding: 4, width: '30%' }}>
                设备风险指数
              </td>
            </tr>
            {detail?.data.devices
              ?.flatMap((d) => d.devices)
              ?.map((d, i) => {
                return (
                  <tr key={nanoid()}>
                    <td style={{ textAlign: 'center', padding: 4, borderTop: '0.5px solid black', borderRight: '0.5px solid black' }}>{i + 1}</td>
                    <td style={{ textAlign: 'center', padding: 4, borderTop: '0.5px solid black', borderRight: '0.5px solid black' }}>
                      {d.device?.displayName}
                    </td>
                    <td style={{ textAlign: 'center', padding: 4, borderTop: '0.5px solid black', borderRight: '0.5px solid black' }}>
                      {d.device.modal}%
                    </td>
                    <td
                      style={{ textAlign: 'center', padding: 4, borderTop: '0.5px solid black', borderRight: '0.5px solid black' }}
                    >{formatter.format(d?.beforeChart?.importance)}</td>
                    <td
                      style={{
                        textAlign: 'center',
                        padding: 4,
                        borderTop: '0.5px solid black',
                        tableLayout: 'fixed',
                        width: '20px',
                        borderRight: '0.5px solid black',
                      }}
                    >{formatter.format(d?.beforeChart?.health)}</td>
                    <td style={{ textAlign: 'center', padding: 4, width: '30%', borderTop: '0.5px solid black' }}>
                      {d?.beforeChart?.healthPlace?.name}
                    </td>
                  </tr>
                )
              })}
          </table>
          <h4 style={{ marginTop: 0, borderTop: '2px double black', borderBottom: '2px double black', backgroundColor: '#f2f2f2', padding: 8 }}>
            主要风险隐患
          </h4>
          <table
            style={{
              width: '100%',
              margin: 0,
              tableLayout: 'fixed',
              borderBottom: '2px double black',
            }}
          >
            <tr style={{ fontWeight: 'normal' }}>
              <td key={nanoid()} style={{ width: '5%', borderRight: '0.5px solid black' }}>
                序号
              </td>
              <td style={{ textAlign: 'center', padding: 4, width: '46%', borderRight: '0.5px solid black' }}>问题描述</td>
              <td style={{ textAlign: 'center', padding: 4, width: '46%', borderRight: '0.5px solid black' }}>备注</td>
            </tr>
            {uniqBy(
              detail?.data.devices
                ?.flatMap((d) => d.devices)
                .flatMap((d) => d.data)
                .flatMap((d) => d.result.checkItems)
                .flatMap((c) => c.points)
                .filter((o) => o.result.code === '2'),
              'id'
            )?.map((d, i) => {
              return (
                <tr key={nanoid()}>
                  <td style={{ textAlign: 'center', padding: 4, borderTop: '0.5px solid black', borderRight: '0.5px solid black' }}>{i + 1}</td>
                  <td style={{ textAlign: 'center', padding: 4, borderTop: '0.5px solid black', borderRight: '0.5px solid black', display: 'flex' }}>
                    <div style={{ flex: 1, verticalAlign: 'middle' }}> {getText(d)}</div>
                    <div style={{ flex: 1 }}>
                      <Image id={d.result.beforPhoto} style={{ maxWidth: 230, maxHeight: 230 }} />
                    </div>
                  </td>
                  <td style={{ textAlign: 'center', padding: 4, borderTop: '0.5px solid black' }}></td>
                </tr>
              )
            })}
          </table>
          <ul style={{ marginTop: 16 }}>
            <li>
              <h4>小结:</h4>
              <textarea
                id='DeviceProblemSummaryRemark'
                value={summaries?.DeviceProblemSummaryRemark}
                readOnly={readonly}
                onChange={(e) => setSummaries({ ...summaries, DeviceProblemSummaryRemark: e.target.value })}
                name='DeviceProblemSummaryRemark'
                className='k-textbox'
                rows='4'
              ></textarea>
            </li>
          </ul>
          <h3 style={{ marginTop: 8 }}>{detail?.station?.name}运行环境风险指数</h3>
          <h4 style={{ marginTop: 16, borderTop: '2px double black', borderBottom: '2px double black', backgroundColor: '#f2f2f2', padding: 8 }}>
            {detail?.station?.name}运行环境风险指数
          </h4>
          <div style={{ display: 'flex', padding: 0, margin: 0 }}>
            <div style={{ width: '50%', padding: 16, height: 280, flex: 1 }}>
              <ScatterChart
                data={[{ value: [detail?.environmentalCharData?.health*100, detail?.environmentalCharData?.importance*100], type: 'circle' }]}
                width={'100%'}
                style={{ width: '100%', height: '280px' }}
              />
            </div>
            <div style={{ width: '50%', padding: 0, height: 280, flex: 1 }}>
              <table style={{ border: 'none', width: '100%', margin: 0 }}>
                {detail?.stationSummaries.map((s, i) => {
                  return (
                    <tr key={nanoid()}>
                      <td style={{ padding: 4, borderTop: i === 0 ? 'none' : '0.5px solid black' }}>{s.name}</td>
                      <td style={{ padding: 4, borderTop: i === 0 ? 'none' : '0.5px solid black', borderLeft: '0.5px solid black' }}>{s.unit}</td>
                      <td style={{ padding: 4, borderTop: i === 0 ? 'none' : '0.5px solid black', borderLeft: '0.5px solid black' }}>{s.value}</td>
                    </tr>
                  )
                })}
              </table>
            </div>
          </div>
          <div style={{ display: 'flex', padding: 0, margin: 0, borderTop: '0.5px solid black' }}>
            <div style={{ padding: 16, height: 56, flex: 0.5 }}>
              设备整体
              <br />
              风险指数
            </div>
            <div style={{ padding: 16, height: 56, flex: 1, fontSize: 24, color: 'red' }}>
              {formatter.format (detail?.environmentalCharData?.health)}
            </div>
            <div style={{ padding: 16, height: 56, flex: 0.5 }}>
              设备整体
              <br />
              重要性指数
            </div>
            <div style={{ padding: 16, height: 56, flex: 1, fontSize: 24, color: 'green' }}>
              {formatter.format(detail?.environmentalCharData?.importance)}
            </div>
          </div>
          <h4 style={{ marginTop: 16, borderTop: '2px double black', borderBottom: '2px double black', backgroundColor: '#f2f2f2', padding: 8 }}>
            运行环境风险指数组成
          </h4>
          <table
            style={{
              width: '100%',
              margin: 0,
              tableLayout: 'fixed',
            }}
          >
            <tr style={{ fontWeight: 'normal' }}>
              <td key={nanoid()} style={{ width: '5%', borderRight: '0.5px solid black' }}>
                序号
              </td>
              <td style={{ textAlign: 'center', padding: 4, width: '30%', borderRight: '0.5px solid black' }}>检查类别</td>
              <td style={{ textAlign: 'center', padding: 4, width: '17%', borderRight: '0.5px solid black' }}>风险指数</td>
              <td style={{ textAlign: 'center', padding: 4, width: '17%', borderRight: '0.5px solid black' }}>风险级别</td>
            </tr>
            {detail?.station.parameters?.map((d, i) => {
              return (
                <tr key={nanoid()}>
                  <td style={{ textAlign: 'center', padding: 4, borderTop: '0.5px solid black', borderRight: '0.5px solid black' }}>{i + 1}</td>
                  <td style={{ textAlign: 'center', padding: 4, borderTop: '0.5px solid black', borderRight: '0.5px solid black' }}>{d.name}</td>
                  <td style={{ textAlign: 'center', padding: 4, borderTop: '0.5px solid black', borderRight: '0.5px solid black' }}>
                    {formatter.format(d?.charData?.health)}
                  </td>
                  <td style={{ textAlign: 'center', padding: 4, borderTop: '0.5px solid black', borderRight: '0.5px solid black' }}>
                    {d?.charData?.healthPlace?.name}
                  </td>
                </tr>
              )
            })}
          </table>
          <h4 style={{ marginTop: 0, borderTop: '2px double black', borderBottom: '2px double black', backgroundColor: '#f2f2f2', padding: 8 }}>
            主要风险隐患
          </h4>
          <table
            style={{
              width: '100%',
              margin: 0,
              tableLayout: 'fixed',
              borderBottom: '2px double black',
            }}
          >
            <tr style={{ fontWeight: 'normal' }}>
              <td key={nanoid()} style={{ width: '5%', borderRight: '0.5px solid black' }}>
                序号
              </td>
              <td style={{ textAlign: 'center', padding: 4, width: '46%', borderRight: '0.5px solid black' }}>问题描述</td>
              <td style={{ textAlign: 'center', padding: 4, width: '46%', borderRight: '0.5px solid black' }}>备注</td>
            </tr>
            {uniqBy(
              detail?.station.parameters
                ?.flatMap((d) => d.datas)
                .flatMap((d) => d.parameters)
                .flatMap((d) => Object.values(d))
                .filter((o) => o.options.find((i) => i.id == o?.result?.option)?.code === '2'),
              'id'
            )?.map((d, i) => {
              return (
                <tr key={nanoid()}>
                  <td style={{ textAlign: 'center', padding: 4, borderTop: '0.5px solid black', borderRight: '0.5px solid black' }}>{i + 1}</td>
                  <td style={{ textAlign: 'center', padding: 4, borderTop: '0.5px solid black', borderRight: '0.5px solid black', display: 'flex' }}>
                    <div style={{ flex: 1, verticalAlign: 'middle' }}> {getText(d)}</div>
                    <div style={{ flex: 1 }}>
                      <Image id={d.result.beforPhoto} style={{ maxWidth: 230, maxHeight: 230 }} />
                    </div>
                  </td>
                  <td style={{ textAlign: 'center', padding: 4, borderTop: '0.5px solid black' }}></td>
                </tr>
              )
            })}
          </table>
          <ul style={{ marginTop: 16 }}>
            <li>
              <h4>小结:</h4>
              <textarea
                id='ExpProblemSummaryRemark'
                value={summaries?.ExpProblemSummaryRemark}
                readOnly={readonly}
                onChange={(e) => setSummaries({ ...summaries, ExpProblemSummaryRemark: e.target.value })}
                name='ExpProblemSummaryRemark'
                className='k-textbox'
                rows='4'
              ></textarea>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
})
