import React, { forwardRef, useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import { symbol } from 'd3'

export const ScatterChart = forwardRef(({ data, style }, ref) => {
  const charRef = useRef(null)
  useEffect(() => {
    console.log(data)
    const myChart = echarts.init(charRef.current)
    let option = {
      xAxis: {
        type: 'value',
        name: '风险指数（%）',
        max: 100,
        min: 0,
        nameGap: 25,
        nameLocation: 'middle',
        nameTextStyle: {
          fontSize: 14,
        },
        splitLine: {
          show: true,
        },
        axisLabel: {
          formatter: '{value}',
        },
      },
      yAxis: {
        type: 'value',
        name: '重要等级（%）',
        max: 100,
        min: 0,
        nameTextStyle: {
          fontSize: 14,
        },
        splitLine: {
          show: true,
        },
        axisLabel: {
          formatter: '{value}',
        },
      },
      series: data.map((v) => {
        return {
          symbolSize: 8,
          data: [v.value],
          type: 'scatter',
          symbol: v.type,
        }
      }),
    }

    myChart.setOption(option)
  }, [data])
  return <div id='chart' ref={charRef} style={style}></div>
})
