import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Input } from '@abb/abb-common-ux-react'
import axios from 'axios'
import { useMountedState } from 'common/useMountedState'
import { ToolPanel, CommonTable, CommonConfirm, SearchPanel } from '..'
import { getClientHeight, getClientWidth } from 'slices/appSlice'
import { Row, Col } from 'components/Grid'

import { showNotification, NotificationType } from 'components/Notification/notificationSlice'
import { useDispatch, useSelector } from 'react-redux'

import { t } from 'i18next'
import { id } from 'date-fns/locale'

export const CommonIndex = forwardRef(
  (
    {
      children, //不在支持
      columns, //列定义
      style,
      getList, //获取数据endpoint
      getGetListInput, //获取数据调用接口传递的参数
      deleteData, //删除数据endpoint
      getDeleteInput, //获取删除参数输入
      FormEdit, //编辑窗口
      otherMenu, //其他菜单
      searchChildren, //查询的其他参数
      searchItem, //查询项
      searchData, //查询数据
      onSearchDataChange, //查询数据变化时
      enableQueryOnSearchDataChange, //开启searchData变化时刷新，默认开启
      toolChildren, //工具栏其他按钮
      actionRender, //自定义操作列
      canShow, //是否可以查看
      canAdd, //是否可以添加数据
      canEdit, //是否可以编辑
      canDelete, //是否可以删除
      canBatchDel, //是否可以批量删除
      canOutput, //是否可以输出
      canInport, //是否可以导入
      onChangeData, //data修改时触发
      onDeleteData, //删除时触发
      refreshAfterDelete, //删除后是否刷新列表
      enableSorting, //开启排序功能
      leftPanelWidth, //左侧宽度
      leftPanel, //左侧插槽
      rightPanelWidth, //左侧宽度
      rightPanel, //左侧插槽
      showPage, //是否显示分页
      pageSize, //页面大小
      isLoading = false,
      pageInQuery,
      refreshWhenOpen, //打开时刷新数据
      tableHeight, //表格高度
      tableWidth, //表格宽度
      className, //样式类
      searchClassName, //查询样式
      toolClassName, //工具栏样式
      canSelection, //是否开启选择功能
      onSelectionChange, //选择改变时触发
      onAdd, //点击添加按钮时触发，用于进行添加前的动作，传入回调方法
      onAddBefore, //添加按钮点击后触发，如果返回item则会setFormData(item)
      onImport,
      tools, //工具自定义
      toolWidth, //工具栏宽度
      hasHeader, //是否有头部
      canSearch, //是否可以查询
      multipleChoice, //是否开启多选，默认是多选
      event, //事件
      extraData, //其他需传递的数据
      getResult, //获取结果集
      keyField, //主键字段名
      checkCanDelete, //检查当前行是否可删除
      checkCanEdit, //检查当前行是否可编辑
      checkCanShow,//检查当前行是否可查看
      formDisabled, //窗口是否可修改
      interceptQuery, //拦截查询
    },
    ref
  ) => {
    const isMounted = useMountedState()
    const [formEvent, setFormEvent] = useState(null)
    // const theme = useSelector(getTheme)
    //获取客户区高度
    const clientHeight = useSelector(getClientHeight)
    const clientWidth = useSelector(getClientWidth)
    const defaultPageSize = 10
    const [keyword, setKeyword] = useState('')
    const history = useHistory()
    const location = useLocation()
    const urlQuery = new URLSearchParams(location.search)
    const [pageIndex, setPageIndex] = useState(1)
    const [currentPageSize, setCurrentPageSize] = useState(pageSize || defaultPageSize)
    const [list, setList] = useState(null)
    const [listCount, setListCount] = useState(0)
    const [innerIsLoading, setInnerIsLoading] = useState(isLoading)
    const [activDataIndex, setActivDataIndex] = useState('')
    const [formData, setFormData] = useState(null)
    const [sort, setSort] = useState('')
    const [deleteItem, setDeleteItem] = useState(null)
    const [isDeleting, setIsDeleting] = useState(false)
    const [checkItems, setCheckItems] = useState([])
    const [showBatchDel, setShowBatchDel] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [noDataText, setNoDataText] = useState(null)

    const dispatch = useDispatch()
    const formRef = useRef(null)
    const fetchData = (params) => {
      if (!getList) return
      setInnerIsLoading(true)
      let fetchParams = getGetListInput ? getGetListInput(params) : params
      axios
        .post(getList(), fetchParams)
        .then((e) => {
          if (!isMounted()) {
            return
          }
          if (e.data) {
            let items = getResult ? getResult(e.data) : e.data.items
            setList(items)
            onChangeData && onChangeData(items)
            setListCount(e.data.totalCount || items.length)
          }
        })
        .then((e) => {
          if (!isMounted()) {
            return
          }
          setInnerIsLoading(false)
        })
        .catch((e) => {
          console.log(e.response)
          setInnerIsLoading(false)
          if (e.response.status == 403) {
            setNoDataText(t('t_403Error'))
          } else {
            setNoDataText(e.message)
          }
        })
    }

    useEffect(() => {
      if (refreshWhenOpen === undefined || refreshWhenOpen === true) {
        const _keyword = urlQuery.get('q')
        const _pageIndex = parseInt(urlQuery.get('pageIndex') ?? pageIndex)
        setKeyword(_keyword)
        setPageIndex(_pageIndex)
        fetchData({
          filter: _keyword,
          ...searchData, //加入其他查询条件
          pageIndex: _pageIndex,
          pageSize: currentPageSize,
        })
      }
    }, [])
    const isNeedRefresh = (fieldName) => {
      let obj = searchItem?.find((c) => c.fieldName === fieldName)
      return obj?.enableRefresh
    }
    useEffect(() => {
      if (
        searchData &&
        (isNeedRefresh(searchData.__fieldName) || enableQueryOnSearchDataChange === undefined || enableQueryOnSearchDataChange === true)
      )
        query()
    }, [searchData])
    const onSort = ({ key, sortUp }) => {
      let newsort = key + ' ' + (sortUp ? 'asc' : 'desc')
      setSort(newsort)
      // console.log(newsort)
      query(undefined, newsort)
    }
    const query = (page, newsort, newPageSize) => {
      let searchdata = searchData || {}
      let pagenum = page
      if (pagenum !== undefined) setPageIndex(pagenum)
      else pagenum = pageIndex
      if (newPageSize !== undefined) setCurrentPageSize(newPageSize)
      fetchData({
        filter: keyword,
        ...searchdata,
        sorting: newsort ? newsort : sort,
        pageIndex: pagenum,
        pageSize: newPageSize ?? currentPageSize,
      })
    }
    const handleQuery = () => {
      if (innerIsLoading || isLoading) {
        return
      }
      query(1)
      if (keyword !== null && keyword !== '') {
        urlQuery.set('q', keyword)
        urlQuery.delete('pageIndex')
      } else {
        urlQuery.delete('q')
        urlQuery.delete('pageIndex')
      }
      history.push(`${location.pathname}?${urlQuery.toString()}`)
    }
    const setCurrentPage = (page, newPageSize) => {
      if (innerIsLoading || isLoading) {
        return
      }
      query(page, undefined, newPageSize)
      if (pageInQuery == undefined || pageInQuery) {
        if (page > 0) {
          urlQuery.set('pageIndex', page)
        } else {
          urlQuery.delete('pageIndex')
        }
        history.push(`${location.pathname}?${urlQuery.toString()}`)
      }
    }
    const onSubmit = (d) => {
      if (activDataIndex) {
        setActivDataIndex('')
        setFormData(null)
        query()
      } else {
        query(1)
        urlQuery.delete('pageIndex')
        history.push(`${location.pathname}?${urlQuery.toString()}`)
      }
    }
    const onDeleteConfirm = async () => {
      try {
        setIsDeleting(true)
        let deleteInput = getDeleteInput ? getDeleteInput(deleteItem) : { id: deleteItem.id }
        const response = await axios.post(deleteData(), deleteInput, { params: { id: deleteItem.id } })
        if (response.status === 200 || response.status === 204) {
          // if (response.data.result === ResponseResult.Ok) {
          setActivDataIndex('')
          setFormData(null)
          onDeleteData && onDeleteData(deleteItem)
          if (refreshAfterDelete === undefined || refreshAfterDelete) query()
          dispatch(showNotification(NotificationType.Success, t('t_hasDelete'))) //`数据已删除`
        } else {
          dispatch(showNotification(NotificationType.Warn, t('t_deleteFail'))) //`删除失败`
        }
      } catch (e) {
        console.log(e)
        dispatch(showNotification(NotificationType.Alarm, t('t_deleteFail') + '2')) //`删除失败`
      } finally {
        setDeleteItem(null)
        setIsDeleting(false)
      }
    }
    //批量删除
    const onBatchDeleteConfirm = async () => {
      try {
        setIsDeleting(true)
        let suc = 0,
          fail = 0
        for (let i = 0; i < checkItems.length; i++) {
          const item = checkItems[i]
          let deleteInput = getDeleteInput ? getDeleteInput(item) : { id: item.id }
          const response = await axios.post(deleteData(), deleteInput, { params: { id: deleteItem.id } })
          if (response.status === 200 || response.status === 204) {
            suc++
          } else {
            fail++
          }
        }
        query()
        if (suc > 0) dispatch(showNotification(NotificationType.Success, t('t_hasDelete'))) //`数据已删除`
        if (fail > 0) dispatch(showNotification(NotificationType.Warn, t('t_deleteFail'))) //`删除失败`
      } catch (e) {
        console.log(e)
        dispatch(showNotification(NotificationType.Alarm, t('t_deleteFail') + '2')) //`删除失败`
      } finally {
        setIsDeleting(false)
        setShowBatchDel(false)
      }
    }
    //导出 todo
    const output = () => {
      console.log('run ouput')
    }
    const inport = () => {
      onImport && onImport()
      console.log('run inport')
    }
    //工具栏选项
    const toolOptions = {}
    useEffect(() => {
      if (event) {
        if (event.name == 'add') {
          console.log('addddd')
          add()
        }
      }
    }, [event])
    const add = () => {
      if (onAdd) {
        onAdd(_onAdd)
      } else {
        _onAdd()
      }
    }
    const edit = (item, index) => {
      setDisabled(false)
      showAndSwitchTab(0, item)
    }

    const _onAdd = (item) => {
      setFormEvent({
        name: 'resetForm',
        params: [],
        callback: (initItem) => {
          let itemBefore = onAddBefore && onAddBefore()
          if (item || itemBefore) {
            let newformdata = { ...initItem, ...item, ...itemBefore }
            setFormData(newformdata)
          }
          setShowSidePanel(true)
        },
      })
    }
    if (canAdd)
      //添加
      toolOptions['add'] = {
        text: tools?.add?.text ?? t('b_add'),
        show: tools?.add?.show ?? true,
        isLoading: tools?.add?.type ?? (innerIsLoading || isLoading),
        type: tools?.add?.isLoading ?? 'primary-blue',
        icon: tools?.add?.icon ?? 'abb/plus',
        disabled: tools?.add?.disabled ?? false,
        onClick: add,
      }
    if (canBatchDel)
      //批量删除
      toolOptions['del'] = {
        text: tools?.del?.text ?? t('b_del'),
        show: tools?.del?.show ?? true,
        isLoading: tools?.del?.isLoading ?? (innerIsLoading || isLoading),
        type: tools?.del?.type ?? 'primary-red',
        icon: tools?.del?.icon ?? 'abb/close',
        disabled: !(checkItems && checkItems.length > 0),
        onClick: () => setShowBatchDel(true),
      }
    if (canOutput)
      //输出
      toolOptions['output'] = {
        text: tools?.output?.text ?? t('b_output'),
        show: tools?.output?.show ?? true,
        isLoading: tools?.output?.type ?? (innerIsLoading || isLoading),
        type: tools?.output?.isLoading ?? 'normal',
        icon: tools?.output?.icon ?? 'abb/download',
        disabled: tools?.output?.disabled ?? false,
        onClick: output,
      }
    if (canInport)
      //导入
      toolOptions['inport'] = {
        text: tools?.inport?.text ?? t('b_inport'),
        show: tools?.inport?.show ?? true,
        isLoading: tools?.inport?.type ?? (innerIsLoading || isLoading),
        type: tools?.inport?.isLoading ?? 'normal',
        icon: tools?.inport?.icon ?? 'abb/import',
        disabled: tools?.inport?.disabled ?? false,
        onClick: inport,
      }
    for (const key in tools) {
      if (key !== 'add' && key !== 'del' && key !== 'output' && key !== 'inport') {
        toolOptions[key] = tools[key]
      }
    }
    const setShowSidePanel = (visible) => {
      setFormEvent({
        name: 'setShowSidePanel',
        params: [visible],
      })
      // formRef.current.setShowSidePanel(visible)
    }
    const changeData = (newData) => {
      setList(
        list.map((d) =>
          d['id'] === newData['id']
            ? {
              ...d,
              ...newData,
            }
            : d
        )
      )
    }
    // console.log(tableWidth == undefined ? clientWidth - 250 : tableWidth)
    const showAndSwitchTab = (tabIndex, item) => {
      // console.log(item)
      setActivDataIndex(item['id'])
      setFormData(item)
      setFormEvent({
        name: 'showAndSwitchTab',
        params: [tabIndex],
      })
    }
    const _onSelectionChange = (items) => {
      // console.log(items)
      setCheckItems(items)
      onSelectionChange && onSelectionChange(items)
    }
    useImperativeHandle(ref, () => ({
      query, //刷新数据
      changeData, //修改列表数据
      showAndSwitchTab, //显示边栏并切换tab
      setShowSidePanel,
      setList, //设置数据
      setDeleteItem,
      add,
      edit,
    }))
    const hasTool = () => {
      return JSON.stringify(toolOptions) != '{}' || toolChildren
    }
    const onQuery = () => {
      if (interceptQuery) {
        interceptQuery(handleQuery)
        return
      }
      handleQuery()
    }
    const header = (hasHeader === undefined || hasHeader) && (
      <div style={{ zIndex: 9 }}>
        <Row>
          <Col flex='auto'>
            {(canSearch === undefined || canSearch === true) && (
              <SearchPanel
                onQuery={() => onQuery()}
                className={searchClassName}
                searchItem={searchItem}
                initData={searchData}
                onChange={onSearchDataChange}
              >
                {!searchChildren && !searchItem && (
                  <div className='form-group'>
                    <Input
                      type='normal'
                      dataType='text'
                      sizeClass='small'
                      label={t('t_keyword')}
                      value={keyword}
                      onValueChange={(e) => setKeyword(e)}
                      showClearIcon={true}
                      // icon="abb/search"
                      disabled={false}
                    />
                  </div>
                )}
                {searchChildren}
              </SearchPanel>
            )}
          </Col>
          <Col flex={hasTool() ? (toolWidth ?? 200) + 'px' : '0'}>
            {hasTool() && (
              <ToolPanel options={toolOptions} className={toolClassName}>
                {toolChildren}
              </ToolPanel>
            )}
          </Col>
        </Row>
      </div>
    )
    const getCommonTableWidth = () => {
      let width = tableWidth === undefined ? clientWidth - 100 - (leftPanelWidth ?? 0) - (rightPanelWidth ?? 0) : tableWidth
      return width < 300 ? 300 : width
    }
    const commonTableWidth = getCommonTableWidth()
    const renderBody = (
      <div style={style}>
        <Row>
          <Col flex={leftPanelWidth}>{leftPanel}</Col>
          <Col flex='auto'>
            <CommonTable
              isLoading={innerIsLoading || isLoading}
              zebra={true}
              columns={columns}
              data={list}
              canShow={canShow}
              onShow={(item, index) => {
                // console.log('show')
                setDisabled(true)
                showAndSwitchTab(0, item)
              }}
              canEdit={canEdit}
              onEdit={edit}
              canDelete={canDelete}
              actionRender={actionRender}
              onDelete={(item, index) => setDeleteItem(item)}
              showPage={showPage === undefined ? true : showPage}
              onGotoPage={(v) => setCurrentPage(v + 1)}
              onSetPageSize={(s, p) => {
                setCurrentPage(p + 1, s)
              }}
              rowCount={listCount}
              currentPage={pageIndex}
              currentPageSize={currentPageSize}
              otherMenu={otherMenu}
              enableSorting={enableSorting}
              noDataText={noDataText}
              onSort={onSort}
              height={tableHeight === undefined ? clientHeight - 230 + (hasHeader === false ? 70 : 0) : tableHeight}
              width={commonTableWidth}
              canSelection={canSelection}
              onSelectionChange={_onSelectionChange}
              multipleChoice={multipleChoice}
              keyField={keyField}
              checkCanDelete={checkCanDelete} //检查当前行是否可删除
              checkCanEdit={checkCanEdit} //检查当前行是否可编辑
              checkCanShow={checkCanShow}//
            />
          </Col>
          <Col flex={rightPanelWidth}>{rightPanel}</Col>
        </Row>
      </div>
    )
    const renderEdit = (canAdd || canShow || canEdit) && (
      <FormEdit
        ref={formRef}
        formData={formData}
        onCancel={() => {
          setActivDataIndex('')
          setFormData(null)
        }}
        id={activDataIndex}
        onSubmit={onSubmit}
        event={formEvent}
        disabled={formDisabled || disabled}
        extraData={extraData}
      />
    )
    const renderDel = canDelete && (
      <CommonConfirm
        title={t('t_titleDeleteData')}
        content={t('t_confirmDeleteData')}
        isVisible={deleteItem != null}
        onConfirm={onDeleteConfirm}
        confirmText={t('b_confirm')}
        onCancel={() => setDeleteItem(null)}
        isLoading={isDeleting}
      />
    )
    const renderBatchDel = canBatchDel && (
      <CommonConfirm
        title={t('t_titleDeleteData')}
        content={t('t_confirmDeleteData')}
        isVisible={showBatchDel}
        onConfirm={onBatchDeleteConfirm}
        confirmText={t('b_confirm')}
        onCancel={() => setShowBatchDel(false)}
        isLoading={isDeleting}
      />
    )
    return (
      <div className={className ?? 'admin-main-content with-padding'} data-cy='admin-user-management'>
        {header}
        {renderBody}
        {renderEdit}
        {renderDel}
        {renderBatchDel}
      </div>
    )
  }
)
