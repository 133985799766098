import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import { Checkbox } from '@abb/abb-common-ux-react'
import { CommonFormEdit } from 'components/LoongInCommon'
import { CommonRule } from 'components/LoongInCommon'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { t } from 'i18next'
import { getClientHeight } from 'slices/appSlice'
import { createParameterOptions, updateParameterOptions } from 'common/apis/ezreport'
import { useDataDictionary } from 'hooks/useDataDictionary'

export const OptionFormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  const [rules, setRules] = useState({})
  const [formItem, setFormItem] = useState([])
  const [innerData, setInnerData] = useState({})
  // const [formItem, setFormItem] = useState([]);
  const dt = useDataDictionary('EvaluationRiskLevel')

  //初始化数据
  const initData = {
    name: '',
    code: '',
    itemId: '',
    itemName: '',
    parentId: null,
    value: '',
  }
  //表单数据项
  const changeFormItem = () => {
    const items = [
      {
        type: 'text',
        dataType: 'text',
        label: '检查点名称',
        fieldName: 'itemName',
        visible: !formData || !!formData.itemName,
        disabled: true,
      },
      {
        type: 'dropdown',
        label: '类型',
        fieldName: 'code',
        options: [
          { label: '正常', value: '1' },
          { label: '非正常', value: '2' },
          { label: '不适用', value: '3' },
          { label: '未采集', value: '4' },
        ],
        maxLength: 20,
        visible: !formData || !formData?.parentId,
      },
      {
        type: 'dropdown',
        label: '风险级别',
        fieldName: 'riskLevel',
        options: dt
          ? dt.details.map((c) => {
              return { label: c.displayText, value: c.code }
            })
          : [],
        visible: !!(formData && formData?.parentId),
      },
      {
        type: 'text',
        dataType: 'text',
        label: '内容',
        fieldName: 'name',
        maxLength: 200,
      },
      {
        type: 'text',
        dataType: 'textarea',
        label: '选项分值表达式',
        fieldName: 'valueExpression',
        maxLength: 200,
      },
      {
        type: 'text',
        dataType: 'textarea',
        label: '处理措施',
        fieldName: 'measures',
        maxLength: 200,
      }, {
        type: 'text',
        dataType: 'textarea',
        label: '说明',
        fieldName: 'description',
        maxLength: 200,
      },
    ]
    setFormItem(items)
  }
  const changeRules = () => {
    const newrules = {
      name: [CommonRule.Require],
      code: !formData || !formData?.parentId ? [CommonRule.Require] : [],
      valueExpression: [CommonRule.Require],
    }
    console.log(newrules)
    setRules(newrules)
  }

  useEffect(() => {
    console.log(formData)
    changeRules()
    changeFormItem()
  }, [innerData])
  useEffect(() => {
    setInnerData(formData)
  }, [formData])
  const onChange = (data, preData) => {
    setInnerData(data)
  }
  //获取更新时的参数
  const getUpdateInput = (inputdata) => {
    return {
      code: inputdata.parentId ? '2' : inputdata.code,
      title: inputdata.name,
      riskLevel: inputdata.riskLevel,
      measures:inputdata.measures,
      description:inputdata.description,
      valueExpression: inputdata.valueExpression,
    }
  }
  //获取添加时的参数
  const getCreateInput = (inputdata) => {
    return {
      parameterItemId: inputdata.itemId,
      parentId: inputdata.parentId,
      riskLevel: inputdata.riskLevel,
      measures:inputdata.measures,
      description:inputdata.description,
      code: inputdata.parentId ? '2' : inputdata.code,
      title: inputdata.name,
      valueExpression: inputdata.valueExpression,
    }
  }

  const resetForm = () => {
    return formEditRef.current.resetForm()
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    setShowSidePanel,
    showAndSwitchTab,
    resetForm,
  }))
  //显示边栏
  const setShowSidePanel = (visible) => {
    formEditRef.current.setShowSidePanel(visible)
  }
  //显示边栏并切换tab
  const showAndSwitchTab = (tabIndex) => {
    formEditRef.current.showAndSwitchTab(tabIndex)
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title='检查点选项'
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createParameterOptions}
      updateData={updateParameterOptions}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={formData}
      onSubmit={onSubmit}
      onChange={onChange}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    >
      {/* 其他tab采用div在此添加 */}
    </CommonFormEdit>
  )
})
