import Mock from "mockjs"
import { param2Obj } from "./utils"

const modulesFiles = require.context('./modules', true, /.js$/)
const mocks = modulesFiles.keys().reduce((mocks, modulePath) => {
  // const moduleName = modulePath.replace(/^.\/(.*)\.js/,'$1')
  const value = modulesFiles(modulePath)
  mocks.push(...value.default)
  return mocks
}, [])
function XHR2ExpressReqWrap(respond) {
  return function (options) {
    let result = null
    if (respond instanceof Function) {
      const { body, type, url } = options
      // https://expressjs.com/en/4x/api.html#req
      result = respond({
        method: type,
        body: JSON.parse(body),
        query: param2Obj(url),
      })
    } else {
      result = respond
    }
    return Mock.mock(result)
  }
}
export default (function () {
  Mock.XHR.prototype.withCredentials = false;
  for (const i of mocks) {
    Mock.mock(i.url, i.method || "get", XHR2ExpressReqWrap(i.response))
  }
})()
