import { createSlice } from "@reduxjs/toolkit";


const oauthSlice = createSlice({
  name: "oauth",
  initialState: {
    initialized: false,
    isLoading: false,
    idToken: null,
    accessToken: null,
    refreshToken: null,
    // state: AuthenticationState.Unauthenticated,
  },
  reducers: {
    updateAccessToken(state, action) {
      state.initialized = true
      state.isLoading = false
      state.idToken = action.payload.idToken
      state.accessToken = action.payload.accessToken
      state.refreshToken = ''
    },
    loadingToken(state, action) {
      state.isLoading = true
    }

  },
})

export const { updateAccessToken, loadingToken } = oauthSlice.actions;
export default oauthSlice.reducer;
